import { getAgentErrorSubtitle, getAgentErrorTitle } from "halifax";
import { ErrorTitles } from "redmond";

export const ADD_PAYMENT_METHOD_MODAL_TITLE =
  "Add one of your Capital One credit cards";
export const ADD_ADDITIONAL_PAYMENT_METHOD_CTA = "Add another payment method";
export const BACK_TO_CARD_SELECTION_CTA = "Back to card selection";
export const ADD_PAYMENT_FORM_HEADER_TEXT = (cardName: string) =>
  `Add your <b>${cardName}</b> credit card`;
export const ADD_PAYMENT_FORM_SUBTITLE_TEXT = `You can choose to add your <b>physical card number</b> or <b>your virtual card number</b> if you've been issued one.`;
export const CARD_ENDING_IN_TEXT = "Ending in";
export const CAP_ONE_INVALID_CREDIT_CARD_TITLE =
  "It looks like you're trying to add an invalid credit card.";
export const CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE =
  "Ensure you are using an eligible Capital One credit card.";
export const EDIT_PAYMENT_METHOD = "Edit Payment Method";
export const TRY_AGAIN = "Try Again";
export const UNABLED_TO_ADD_PAYMENT =
  "We are unable to add your payment method";
export const ADD_PAYMENT_AGAIN = "Please add your payment method again";

export const PAYMENT_METHOD_ERROR_TITLES = (error: string): ErrorTitles => {
  switch (error) {
    case "InvalidCVV":
      return {
        agentSubtitle: getAgentErrorSubtitle(error),
        agentTitle: getAgentErrorTitle(error),
        icon: "error",
        title: "CVV Error",
        subtitle:
          "Ensure your CVV / Security Code is entered correctly and try again.",
        primaryButtonText: "Edit payment information",
      };
    case "InvalidAddress":
      return {
        agentSubtitle: getAgentErrorSubtitle(error),
        agentTitle: getAgentErrorTitle(error),
        icon: "error",
        title: "Address Verification Error",
        subtitle:
          "Ensure your Zip Code and Country is entered correctly and try again.",
        primaryButtonText: "Edit payment information",
      };
    default:
    case "GenericError":
      return {
        agentSubtitle: getAgentErrorSubtitle(error),
        agentTitle: getAgentErrorTitle(error),
        icon: "error",
        title: "Error Adding Payment Method",
        subtitle:
          "We were unable to add your payment method. Please ensure all of your information is correct and try again.",
        primaryButtonText: "Edit payment information",
      };
  }
};
