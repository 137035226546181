import React, { useState, useEffect } from "react";
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core";
import useWindowEventListener from "./hooks/useWindowEventListener";
import { eventsToListen } from "./utils/events";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import * as frenchTranslations from "./lang/fr.json";
import * as englishTranslations from "./lang/en.json";
import {
  CustomerProfileClientAssetProps,
  CustomerProfileModuleProps,
} from "redmond";
import ExperimentsProvider from "./context/experiments";
import UserSourceProvider from "./context/userSource";
import fetchUserInfo from "./api/v1/user/fetchUserInfo";
import AxiosInterceptors from "./components/AxiosInterceptors";
import { AgentBanner } from "halifax";

import { store } from "./store";
import { RewardsBanner } from "./modules/rewards/components";
import { PATH_HOME } from "./utils/paths";
import { CustomerProfileLanding } from "./pages/CustomerProfile";

function loadLocaleData(locale: string): any {
  switch (locale) {
    case "fr":
      return frenchTranslations;
    default:
      return englishTranslations;
  }
}
const generateClassName = createGenerateClassName({
  productionPrefix: "ptCustomerProfileModule",
  seed: "ptCustomerProfileModule",
});

export const ClientContext = React.createContext<
  Partial<CustomerProfileClientAssetProps>
>({});

const App = (props: CustomerProfileModuleProps) => {
  const { experiments, baseHistory, isAgentPortal, clientAssets } = props;
  // const { matchesMobile } = useDeviceTypes();
  const [activeTheme, setActiveTheme] = useState(props.theme);
  const [locale, setLocale] = useState(props.language);
  const [messages, setMessages] = useState(loadLocaleData(locale).default);
  const [sessionInfo, setSessionInfo] = useState(clientAssets.sessionInfo);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userInfoResponse = await fetchUserInfo();
        setSessionInfo(userInfoResponse);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUser();
  }, []);

  const handleThemeChanged = (e: CustomEvent) => {
    setActiveTheme(e.detail);
    console.log("THEME RECEIVED:", e.detail.palette.type);
  };
  const handleLocaleChanged = (e: CustomEvent) => {
    setLocale(e.detail);
    const messages = loadLocaleData(e.detail);
    setMessages(messages.default);
  };
  useWindowEventListener(eventsToListen.HOST_THEME_CHANGED, handleThemeChanged);
  useWindowEventListener(
    eventsToListen.HOST_LOCALE_CHANGED,
    handleLocaleChanged
  );

  const { firstName, lastName } = sessionInfo?.userInfo || {
    firstName: "",
    lastName: "",
  };

  return (
    <Provider store={store}>
      <ExperimentsProvider initState={experiments}>
        <UserSourceProvider>
          <Router history={baseHistory}>
            <AxiosInterceptors isAgentPortal={isAgentPortal} />
            <ClientContext.Provider value={{ ...clientAssets, sessionInfo }}>
              <div className="App">
                <StylesProvider generateClassName={generateClassName}>
                  <ThemeProvider theme={activeTheme}>
                    {messages != null ? (
                      <IntlProvider
                        locale={locale}
                        defaultLocale="en"
                        messages={messages}
                      >
                        <Switch>
                          {isAgentPortal ? (
                            <Route path="*">
                              <AgentBanner
                                firstName={firstName}
                                lastName={lastName}
                              />
                            </Route>
                          ) : (
                            <Route path="*">
                              <RewardsBanner />
                            </Route>
                          )}
                        </Switch>
                        <Route path={PATH_HOME}>
                          <CustomerProfileLanding />
                        </Route>
                      </IntlProvider>
                    ) : (
                      <div>Loading</div>
                    )}
                  </ThemeProvider>
                </StylesProvider>
              </div>
            </ClientContext.Provider>
          </Router>
        </UserSourceProvider>
      </ExperimentsProvider>
    </Provider>
  );
};

export default App;
