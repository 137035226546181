import { connect, ConnectedProps } from "react-redux";

import { TravelerSection } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { getUserPassengers } from "../../../../modules/preferences/reducer";
import {
  deleteUserPassenger,
  fetchUserPassengers,
  updateUserPassenger,
} from "../../../../modules/preferences/actions/actions";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state: IStoreState) => {
  return {
    travelers: getUserPassengers(state),
  };
};

const mapDispatchToProps = {
  fetchUserPassengers,
  updateUserPassenger,
  deleteUserPassenger,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TravelerSectionConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedTravelerSection = connector(withRouter(TravelerSection));
