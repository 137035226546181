import { put } from "redux-saga/effects";
import { CallState } from "redmond";
import { actions } from "../actions";
import { deleteUserPassenger } from "../../../api/v1/user/deleteUserPassenger";
import {
  IListUserPassengersResponse,
  fetchUserPassengers,
} from "../../../api/v1/user/fetchUserPassengers";

export function* deleteUserPassengerSaga(action: actions.IDeleteUserPassenger) {
  try {
    yield deleteUserPassenger(action.travelerId);

    const userPassengers: IListUserPassengersResponse =
      yield fetchUserPassengers({});

    yield put(
      actions.setUserPassengers({
        userPassengerCallState: CallState.Success,
        userPassengers: userPassengers,
      })
    );
  } catch (e) {
    yield put(actions.setUserPassengersCallStateFailed());
  }
}
