import React from "react";

export const STAR_RATING_TOOLTIP_TEXT = (
  <>
    A hotel star rating system is a widely recognized method used to classify
    and categorize hotels based on their overall <strong>quality</strong>,{" "}
    <strong>amenities</strong>, <strong>services</strong>, and{" "}
    <strong>facilities</strong>. It provides a standardized way for travellers
    to gauge the level of comfort and luxury they can expect from a particular
    hotel.
  </>
);
export const STAR_RATING_HEADING_TEXT = "Rating";
export const STAR_RATING_SECONDARY_TEXT =
  "Select your preferred types of hotels.";

export const AMENITIES_HEADING_TEXT = "Amenities";
export const AMENITIES_SECONDARY_TEXT =
  "Select your preferred set of amenities.";

export const CANCELLATION_HEADING_TEXT = "Cancellation policy";

export const RESET_CTA_TEXT = "Clear preferences";
export const SAVE_CTA_TEXT = "Save preferences";
export const SAVE_CTA_ACTIVE_TEXT = "Saving...";
