import { put } from "redux-saga/effects";
import { actions } from "../actions";
import { UserFlightPreferencesPayload } from "redmond";
import {
  UpdateUserFlightPreferencesPayload,
  updateUserFlightPreferences,
} from "../../../api/v1/user/updateUserFlightPreferences";
import fetchUserFlightPreferences from "../../../api/v1/user/fetchUserFlightPreferences";

export function* updateUserFlightPreferencesSaga(
  action: actions.IUpdateUserFlightPreferences
) {
  try {
    const res: UpdateUserFlightPreferencesPayload =
      yield updateUserFlightPreferences(action.flightPreferences);

    if (res.Response !== "Success") {
      yield put(actions.setUpdateUserFlightPreferencesCallStateFailed());
      throw new Error("Failed to update user flight preferences");
    }

    yield put(actions.setUpdateUserFlightPreferencesCallStateSuccess());

    const newSavedPreferences: UserFlightPreferencesPayload =
      yield fetchUserFlightPreferences();

    yield put(
      actions.setUserFlightPreferences({
        flightPreferences: newSavedPreferences,
      })
    );
  } catch (e) {
    console.error(e);
    yield put(actions.setUserFlightPreferencesCallStateFailed());
  }
}
