import { Box } from "@material-ui/core";
import {
  TravelerSelectStep,
  TravelerSelectWorkflow,
  useDeviceTypes,
} from "halifax";
import React from "react";
import { TravelerSectionConnectorProps } from "./container";
import "./styles.scss";
import clsx from "clsx";
import { PATH_HOME } from "../../../../utils/paths";
import { RouteComponentProps } from "react-router-dom";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { CLICKED_ADD_TRAVELER, ClickedAddTravelerProperties } from "redmond";
import {
  addTrackingProperties,
  useExperiments,
} from "../../../../context/experiments";

export interface TravelerSectionProps
  extends TravelerSectionConnectorProps,
    RouteComponentProps {}

export const TravelerSection = (props: TravelerSectionProps) => {
  const {
    history,
    travelers,
    fetchUserPassengers,
    updateUserPassenger,
    deleteUserPassenger,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const expState = useExperiments();

  const [progress, setProgress] = React.useState<TravelerSelectStep>(
    matchesMobile ? TravelerSelectStep.TravelerSelect : TravelerSelectStep.Main
  );

  React.useEffect(() => {
    fetchUserPassengers();
  }, []);

  return (
    <Box className={clsx("traveler-section", { mobile: matchesMobile })}>
      <TravelerSelectWorkflow
        handleDeletePassenger={deleteUserPassenger}
        handleUpdatePassenger={updateUserPassenger}
        progress={progress}
        setProgress={setProgress}
        disallowSelect
        selectedTravelerIds={[]}
        travelers={travelers}
        noHeadings
        addNewTravelerButton
        showAdditionalInfoSection
        showDriverLicenseSection
        disableDriversLicenseValidation
        showFrequentFlyerSection
        showGenderField
        showNationalityField
        editPassenger={undefined}
        isMobile={matchesMobile}
        onMobileBack={() => {
          history.push(PATH_HOME);
        }}
        updatedDesign
        titles={{
          travelerInfoTitle: "Travelers",
          travelerInfoSubtitle:
            "Manage traveler details and frequent flyer programs to speed up the booking process. ",
          frequentFlyerTitle: "Frequent Flyer Details",
          additionalInfoTitle: "Additional Information",
          addTravelers: "Add New Traveler",
          adultTitle: "Adult",
          childTitle: "Child",
          infantSeatTitle: "Infant (in seat)",
          infantLapTitle: "Infant (on lap)",
          noTravelersTitle: "You haven’t added any travelers yet.",
        }}
        travelerSelectContentOnly
        onClickAddNewTraveler={() => {
          trackEvent({
            eventName: CLICKED_ADD_TRAVELER,
            properties: addTrackingProperties(expState.trackingProperties, {
              entry_type: "customer_profile",
            } as ClickedAddTravelerProperties),
          });
        }}
      />
    </Box>
  );
};
