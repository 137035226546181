import { takeLeading } from "redux-saga/effects";

import { actionTypes } from "../actions";
import { fetchUserPassengersSaga } from "./fetchUserPassengersSaga";
import { fetchUserHotelPreferencesSaga } from "./fetchUserHotelPreferencesSaga";
import { fetchUserContactInfoSaga } from "./fetchUserContactInfoSaga";
import { updateUserPassengerSaga } from "./updateUserPassengerSaga";
import { updateUserHotelPreferencesSaga } from "./updateUserHotelPreferencesSaga";
import { deleteUserPassengerSaga } from "./deleteUserPassengerSaga";
import { fetchUserFlightPreferencesSaga } from "./fetchUserFlightPreferencesSaga";
import { updateUserFlightPreferencesSaga } from "./updateUserFlightPreferencesSaga";
import { listPaymentMethodsSaga } from "./listPaymentMethodsSaga";
import { deletePaymentMethodSaga } from "./deletePaymentMethodSaga";
import { verifyPaymentMethodSaga } from "./verifyPaymentMethodSaga";

export function* watchFetchUserPassengers() {
  yield takeLeading(actionTypes.FETCH_USER_PASSENGERS, fetchUserPassengersSaga);
}

export function* watchFetchUserHotelPreferences() {
  yield takeLeading(
    actionTypes.FETCH_USER_HOTEL_PREFERENCES,
    fetchUserHotelPreferencesSaga
  );
}

export function* watchFetchUserFlightPreferences() {
  yield takeLeading(
    actionTypes.FETCH_USER_FLIGHT_PREFERENCES,
    fetchUserFlightPreferencesSaga
  );
}

export function* watchFetchUserContactInfo() {
  yield takeLeading(
    actionTypes.FETCH_USER_CONTACT_INFO,
    fetchUserContactInfoSaga
  );
}

export function* watchUpdateUserPassengers() {
  yield takeLeading(actionTypes.UPDATE_USER_PASSENGER, updateUserPassengerSaga);
}

export function* watchUpdateUserHotelPreferences() {
  yield takeLeading(
    actionTypes.UPDATE_USER_HOTEL_PREFERENCES,
    updateUserHotelPreferencesSaga
  );
}

export function* watchUpdateUserFlightPreferences() {
  yield takeLeading(
    actionTypes.UPDATE_USER_FLIGHT_PREFERENCES,
    updateUserFlightPreferencesSaga
  );
}

export function* watchDeleteUserPassenger() {
  yield takeLeading(actionTypes.DELETE_USER_PASSENGER, deleteUserPassengerSaga);
}

export function* watchListPaymentMethods() {
  yield takeLeading(actionTypes.LIST_PAYMENT_METHODS, listPaymentMethodsSaga);
}

export function* watchDeletePaymentMethod() {
  yield takeLeading(actionTypes.DELETE_PAYMENT_METHOD, deletePaymentMethodSaga);
}

export function* watchVerifyPaymentMethod() {
  yield takeLeading(actionTypes.VERIFY_PAYMENT_METHOD, verifyPaymentMethodSaga);
}
