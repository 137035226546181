import React from "react";
import {
  ButtonWrap,
  CategoryType,
  CloseButtonIcon,
  Icon,
  IconName,
  MobileAutocompleteOptions,
  MobilePopoverCard,
} from "halifax";
import "./styles.scss";
import { Box, TextField, Typography } from "@material-ui/core";
import { ITripTerminus } from "redmond";
import {
  HOME_AIRPORT_INPUT_LABEL,
  HOME_AIRPORT_POPOVER_TITLE,
} from "./textConstants";

interface MobileLocationSearchModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  fetchLocationCategories: (searchText: string) => void;
  originLoading: boolean;
  setOrigin: (origin: ITripTerminus | null) => void;
  origin: ITripTerminus | null;
  originOpts: CategoryType<ITripTerminus>[] | null;
}

export const MobileLocationSearchModal = ({
  open,
  setOpen,
  fetchLocationCategories,
  originLoading,
  setOrigin,
  origin,
  originOpts,
}: MobileLocationSearchModalProps) => {
  const [inputValue, setInputValue] = React.useState<string>(
    origin?.label || ""
  );

  React.useEffect(() => {
    if (origin?.label) {
      setInputValue(origin.label);
    }
  }, [origin?.label]);

  return (
    <MobilePopoverCard
      className="mobile-home-airport-search-popover"
      open={open}
      onClose={() => setOpen(false)}
      topRightButton={<CloseButtonIcon onClick={() => setOpen(false)} />}
      centered={true}
      headerElement={
        <Typography
          variant="h2"
          className="mobile-home-airport-popover-header-text"
        >
          {HOME_AIRPORT_POPOVER_TITLE}
        </Typography>
      }
    >
      <Box className="mobile-home-airport-search-popover-content">
        <Box className="mobile-flights-search-location-input-field">
          <Icon name={IconName.B2BMapPin} />
          <TextField
            className="location-auto-complete mobile"
            label={HOME_AIRPORT_INPUT_LABEL}
            InputProps={{
              disableUnderline: true,
            }}
            autoFocus
            onChange={(e) => {
              fetchLocationCategories(e.target.value);
              setInputValue(e.target.value);
            }}
            value={inputValue}
          />

          {origin && (
            <ButtonWrap
              onClick={() => {
                setOrigin(null);
                setInputValue("");
              }}
            >
              <Icon name={IconName.XCircle} />
            </ButtonWrap>
          )}
        </Box>
        <MobileAutocompleteOptions
          value={null}
          valueCategories={originOpts}
          setValue={(value): void => {
            if (value) {
              setOrigin(value);
              setInputValue(value.label);
              setOpen(false);
            }
          }}
          loading={originLoading}
          loadingText="Loading"
          hideSidePadding
        />
      </Box>
    </MobilePopoverCard>
  );
};
