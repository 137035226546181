// Getters
export const FETCH_USER_PASSENGERS =
  "customerProfilePreferences/FETCH_USER_PASSENGERS";
export type FETCH_USER_PASSENGERS = typeof FETCH_USER_PASSENGERS;

export const FETCH_USER_HOTEL_PREFERENCES =
  "customerProfilePreferences/FETCH_USER_HOTEL_PREFERENCES";
export type FETCH_USER_HOTEL_PREFERENCES = typeof FETCH_USER_HOTEL_PREFERENCES;

export const FETCH_USER_CONTACT_INFO =
  "customerProfilePreferences/FETCH_USER_CONTACT_INFO";
export type FETCH_USER_CONTACT_INFO = typeof FETCH_USER_CONTACT_INFO;

export const FETCH_USER_FLIGHT_PREFERENCES =
  "customerProfilePreferences/FETCH_USER_FLIGHT_PREFERENCES";
export type FETCH_USER_FLIGHT_PREFERENCES =
  typeof FETCH_USER_FLIGHT_PREFERENCES;

// Setters

export const UPDATE_USER_PASSENGER =
  "customerProfilePreferences/UPDATE_USER_PASSENGER";
export type UPDATE_USER_PASSENGER = typeof UPDATE_USER_PASSENGER;

export const DELETE_USER_PASSENGER =
  "customerProfilePreferences/DELETE_USER_PASSENGER";
export type DELETE_USER_PASSENGER = typeof DELETE_USER_PASSENGER;

export const SET_USER_PASSENGERS =
  "customerProfilePreferences/SET_USER_PASSENGERS";
export type SET_USER_PASSENGERS = typeof SET_USER_PASSENGERS;

export const SET_USER_PASSENGERS_CALL_STATE_FAILED =
  "customerProfilePreferences/SET_USER_PASSENGERS_CALL_STATE_FAILED";
export type SET_USER_PASSENGERS_CALL_STATE_FAILED =
  typeof SET_USER_PASSENGERS_CALL_STATE_FAILED;

export const UPDATE_USER_HOTEL_PREFERENCES =
  "customerProfilePreferences/UPDATE_USER_HOTEL_PREFERENCES";
export type UPDATE_USER_HOTEL_PREFERENCES =
  typeof UPDATE_USER_HOTEL_PREFERENCES;

export const SET_USER_HOTEL_PREFERENCES =
  "customerProfilePreferences/SET_USER_HOTEL_PREFERENCES";
export type SET_USER_HOTEL_PREFERENCES = typeof SET_USER_HOTEL_PREFERENCES;

export const SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED =
  "customerProfilePreferences/SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED";
export type SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED =
  typeof SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED;

export const SET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE_SUCCESS =
  "customerProfilePreferences/SET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE_SUCCESS";
export type SET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE_SUCCESS =
  typeof SET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE_SUCCESS;

export const SET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED =
  "customerProfilePreferences/SET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED";
export type SET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED =
  typeof SET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED;

export const RESET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE =
  "customerProfilePreferences/RESET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE";
export type RESET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE =
  typeof RESET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE;

export const SET_USER_CONTACT_INFO_CALL_STATE_FAILED =
  "customerProfilePreferences/SET_USER_CONTACT_INFO_CALL_STATE_FAILED";
export type SET_USER_CONTACT_INFO_CALL_STATE_FAILED =
  typeof SET_USER_CONTACT_INFO_CALL_STATE_FAILED;

export const SET_USER_CONTACT_INFO =
  "customerProfilePreferences/SET_USER_CONTACT_INFO";
export type SET_USER_CONTACT_INFO = typeof SET_USER_CONTACT_INFO;

export const UPDATE_USER_FLIGHT_PREFERENCES =
  "customerProfilePreferences/UPDATE_USER_FLIGHT_PREFERENCES";
export type UPDATE_USER_FLIGHT_PREFERENCES =
  typeof UPDATE_USER_FLIGHT_PREFERENCES;

export const SET_USER_FLIGHT_PREFERENCES =
  "customerProfilePreferences/SET_USER_FLIGHT_PREFERENCES";
export type SET_USER_FLIGHT_PREFERENCES = typeof SET_USER_FLIGHT_PREFERENCES;

export const SET_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED =
  "customerProfilePreferences/SET_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED";
export type SET_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED =
  typeof SET_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED;

export const SET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED =
  "customerProfilePreferences/SET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED";
export type SET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED =
  typeof SET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED;

export const RESET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE =
  "customerProfilePreferences/RESET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE";
export type RESET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE =
  typeof RESET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE;

export const SET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE_SUCCESS =
  "customerProfilePreferences/SET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE_SUCCESS";
export type SET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE_SUCCESS =
  typeof SET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE_SUCCESS;
export const LIST_PAYMENT_METHODS =
  "customerProfilePreferences/LIST_PAYMENT_METHODS";
export type LIST_PAYMENT_METHODS = typeof LIST_PAYMENT_METHODS;

export const FETCH_PAYMENT_METHOD =
  "customerProfilePreferences/FETCH_PAYMENT_METHOD";
export type FETCH_PAYMENT_METHOD = typeof FETCH_PAYMENT_METHOD;

export const SET_PAYMENT_METHODS =
  "customerProfilePreferences/SET_PAYMENT_METHODS";
export type SET_PAYMENT_METHODS = typeof SET_PAYMENT_METHODS;

export const VERIFY_PAYMENT_METHOD =
  "customerProfilePreferences/VERIFY_PAYMENT_METHOD";
export type VERIFY_PAYMENT_METHOD = typeof VERIFY_PAYMENT_METHOD;

export const VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED =
  "customerProfilePreferences/VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED";
export type VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED =
  typeof VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED;

export const VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS =
  "customerProfilePreferences/VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS";
export type VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS =
  typeof VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS;

export const DELETE_PAYMENT_METHOD =
  "customerProfilePreferences/DELETE_PAYMENT_METHOD";
export type DELETE_PAYMENT_METHOD = typeof DELETE_PAYMENT_METHOD;

export const DELETE_PAYMENT_METHOD_CALL_STATE_FAILED =
  "customerProfilePreferences/SET_PAYMENT_METHOD_CALL_STATE_FAILED";
export type DELETE_PAYMENT_METHOD_CALL_STATE_FAILED =
  typeof DELETE_PAYMENT_METHOD_CALL_STATE_FAILED;

export const DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS =
  "customerProfilePreferences/DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS";
export type DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS =
  typeof DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS;

export const SET_PAYMENT_METHOD_CALL_STATE_FAILED =
  "customerProfilePreferences/SET_PAYMENT_METHOD_CALL_STATE_FAILED";
export type SET_PAYMENT_METHOD_CALL_STATE_FAILED =
  typeof SET_PAYMENT_METHOD_CALL_STATE_FAILED;

export const SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  "customerProfilePreferences/SET_PAYMENT_METHODS_CALL_STATE_FAILED";
export type SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  typeof SET_PAYMENT_METHODS_CALL_STATE_FAILED;
