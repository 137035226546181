import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  B2BPaymentMethodSelectWorkflow,
  B2BSpinner,
  CheckoutPaymentForm,
  GenericInfoPopup,
  Icon,
  IconName,
  LoadingIndicator,
  LoadingPopup,
  PoweredByHopper,
  TEST_CARD_LAST_FOURS,
  useDeviceTypes,
} from "halifax";
import { PaymentMethodsSectionConnectorProps } from "./container";
import clsx from "clsx";
import { RouteComponentProps } from "react-router-dom";
import { CallState, TokenizeCardErrors } from "redmond";
import {
  ADD_ADDITIONAL_PAYMENT_METHOD_CTA,
  ADD_PAYMENT_AGAIN,
  ADD_PAYMENT_FORM_HEADER_TEXT,
  ADD_PAYMENT_FORM_SUBTITLE_TEXT,
  ADD_PAYMENT_METHOD_MODAL_TITLE,
  BACK_TO_CARD_SELECTION_CTA,
  CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE,
  CAP_ONE_INVALID_CREDIT_CARD_TITLE,
  CARD_ENDING_IN_TEXT,
  EDIT_PAYMENT_METHOD,
  TRY_AGAIN,
  UNABLED_TO_ADD_PAYMENT,
  PAYMENT_METHOD_ERROR_TITLES,
} from "./textConstants";
import { config } from "../../../../api/config";
import "./styles.scss";

export interface PaymentMethodsSectionProps
  extends PaymentMethodsSectionConnectorProps,
    RouteComponentProps {}

export const PaymentMethodsSection = (props: PaymentMethodsSectionProps) => {
  const {
    rewardsAccounts,
    paymentMethods,
    listPaymentMethods,
    deletePaymentMethod,
    listPaymentMethodCallState,
    deletePaymentMethodCallState,
    verifyPaymentMethodCallState,
    verifyPaymentMethod,
    verifyPaymentMethodResults,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const [openErrorPaymentModal, setOpenErrorPaymentModal] = useState(false);
  const [isNotCapOneAccount, setIsNotCapOneAccount] = useState(false);
  const [tokenizeErrors, setTokenizeErrors] = useState<TokenizeCardErrors[]>(
    []
  );

  React.useEffect(() => {
    listPaymentMethods();
  }, []);

  const handleOnAddPaymentMethod = (token: string, last4: string) => {
    // note: cap1 specific logic:
    // A card should be deemed ineligible if the last four digits do not match one of the cards associated with their accounts.
    const account = rewardsAccounts.find(
      (account) =>
        account.lastFour === last4 ||
        account.lastFourVirtualCardNumbers?.includes(last4)
    );
    let matchingRewardsAccount = account;

    const isAddingVCNPaymentMethod = !!(
      matchingRewardsAccount?.lastFourVirtualCardNumbers &&
      matchingRewardsAccount?.lastFourVirtualCardNumbers?.includes(last4)
    );

    // TODO: bad practice, remove this in favor of real test accounts when we have them
    const isTestCard =
      window.__mclean_env__.ENV !== "production" &&
      TEST_CARD_LAST_FOURS.includes(last4);
    if (isTestCard) {
      matchingRewardsAccount = rewardsAccounts[0];
    }

    if (!!matchingRewardsAccount || isTestCard) {
      verifyPaymentMethod(
        { token },
        matchingRewardsAccount?.accountReferenceId!,
        isAddingVCNPaymentMethod
      );
    } else {
      setIsNotCapOneAccount(true);
      setOpenErrorPaymentModal(true);
    }
  };

  const handleCloseErrorPopup = () => {
    isNotCapOneAccount && setIsNotCapOneAccount(false);
    setOpenErrorPaymentModal(false);
  };

  useEffect(() => {
    if (verifyPaymentMethodCallState === CallState.Failed) {
      setOpenErrorPaymentModal(true);
    }
  }, [verifyPaymentMethodCallState]);

  const PaymentAddingElement = () => (
    <LoadingPopup
      indicatorSize={"small"}
      indicator={B2BSpinner}
      open={true}
      popupSize={"small"}
      message={"Adding your payment method"}
      footer={PoweredByHopper}
    />
  );

  const renderCheckoutPaymentForm = () => {
    return (
      <CheckoutPaymentForm
        loading={verifyPaymentMethodCallState === CallState.InProcess}
        loadingEl={<PaymentAddingElement />}
        onSubmit={(token, last4) => {
          handleOnAddPaymentMethod(token, last4);
        }}
        saveLabel={"Save"}
        onError={(errors) => {
          setTokenizeErrors(errors);
          setOpenErrorPaymentModal(true);
        }}
        spreedlyEnvironmentKey={config.spreedlyEnvironmentKey}
        isMobile={matchesMobile}
        className="b2b"
      />
    );
  };

  const paymentMethodWorkflowTitles = () => ({
    addPaymentCta: "Add payment method",
    addPaymentModalTitle: ADD_PAYMENT_METHOD_MODAL_TITLE,
    addAdditionalPaymentCta: ADD_ADDITIONAL_PAYMENT_METHOD_CTA,
    backToCardSelectionCta: BACK_TO_CARD_SELECTION_CTA,
    paymentFormHeader: (cardName: string) => (
      <Typography
        variant={"h4"}
        dangerouslySetInnerHTML={{
          __html: ADD_PAYMENT_FORM_HEADER_TEXT(cardName),
        }}
      ></Typography>
    ),
    paymentFormSubtitle: ADD_PAYMENT_FORM_SUBTITLE_TEXT,

    cardEndingIn: CARD_ENDING_IN_TEXT,
    noCardAddedTitle:
      paymentMethods.length === 0
        ? "You haven't added a payment method yet."
        : undefined,
    usePlusIconInCTA: false,
    showAddPaymentIcon: matchesMobile,
  });

  return (
    <>
      {listPaymentMethodCallState === CallState.InProcess ||
      deletePaymentMethodCallState === CallState.InProcess ? (
        <LoadingIndicator
          indicatorSize={"small"}
          indicator={B2BSpinner}
          message={
            listPaymentMethodCallState === CallState.InProcess
              ? `Fetching`
              : `Deleting`
          }
        />
      ) : (
        <Box
          className={clsx("payment-methods-section", { mobile: matchesMobile })}
        >
          <B2BPaymentMethodSelectWorkflow
            rewardsAccounts={rewardsAccounts ?? []}
            savedPayments={paymentMethods ?? []}
            disabled={openErrorPaymentModal}
            removePaymentMethod={(paymentId: string) => {
              deletePaymentMethod({ paymentId });
            }}
            titles={paymentMethodWorkflowTitles()}
            renderCheckoutPaymentForm={renderCheckoutPaymentForm}
            isMobile={matchesMobile}
            loading={verifyPaymentMethodCallState === CallState.InProcess}
            buttonClassName="b2b"
            fullScreenWithBanner={matchesMobile}
            paymentMethodDisabled={(_r) => false}
            showEarnValue={false}
            isVCNEnabled={false}
          />
          <GenericInfoPopup
            open={openErrorPaymentModal}
            image={
              <Icon
                className="error-icon"
                name={
                  isNotCapOneAccount
                    ? IconName.ErrorState
                    : IconName.UnableToProcess
                }
              />
            }
            {...(verifyPaymentMethodResults &&
            verifyPaymentMethodCallState === CallState.Failed
              ? {
                  ...PAYMENT_METHOD_ERROR_TITLES(verifyPaymentMethodResults),
                  buttons: [
                    {
                      buttonText: EDIT_PAYMENT_METHOD,
                      onClick: () => {
                        handleCloseErrorPopup();
                      },
                      defaultStyle: "h4r-primary",
                    },
                  ],
                }
              : {
                  ...{
                    title: isNotCapOneAccount
                      ? CAP_ONE_INVALID_CREDIT_CARD_TITLE
                      : UNABLED_TO_ADD_PAYMENT,
                    subtitle: isNotCapOneAccount
                      ? CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE
                      : tokenizeErrors.length > 0
                      ? tokenizeErrors[0].message
                      : ADD_PAYMENT_AGAIN,
                    buttons: [
                      {
                        buttonText: EDIT_PAYMENT_METHOD,
                        onClick: () => {
                          handleCloseErrorPopup();
                        },
                        defaultStyle: "h4r-secondary",
                      },
                      {
                        buttonText: TRY_AGAIN,
                        onClick: () => {
                          handleCloseErrorPopup();
                        },
                        defaultStyle: "h4r-primary",
                        buttonWrapperClassName: "b2b",
                      },
                    ],
                  },
                })}
            isMobile={matchesMobile}
            className="payment-section-error-modal"
          ></GenericInfoPopup>
        </Box>
      )}
    </>
  );
};
