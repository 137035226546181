import { Box, Typography } from "@material-ui/core";
import {
  ButtonWrap,
  Icon,
  IconName,
  SecondaryHeader,
  useDeviceTypes,
} from "halifax";
import React, { useContext } from "react";
import { ClientContext } from "../../App";
import "./styles.scss";
import { Link, NavLink, RouteComponentProps } from "react-router-dom";
import { CustomerProfileLandingConnectorProps } from "./container";
import { BASE_PATH_HOME, PATH_HOME } from "../../utils/paths";
import {
  FOOTER_CTA_TEXT,
  FOOTER_PRIMARY_TEXT,
  FOOTER_SECONDARY_TEXT,
  MY_PROFILE,
  SEARCH,
  SUBTITLE,
  getTitle,
} from "./textConstants";
import clsx from "clsx";
import queryStringParser from "query-string";
import { ContactInfo } from "./components/ContactInfo";
import { TravelerSection } from "./components/TravelersSection";
import { HotelPreferences } from "./components/HotelPreferences";
import { FlightPreferences } from "./components/FlightPreferences";
import { PaymentMethodsSection } from "./components/PaymentMethodsSection";
import { trackEvent } from "../../api/v1/analytics/trackEvent";
import {
  CUSTOMER_PROFILE_ENTRY,
  CustomerProfileEntryProperties,
} from "redmond";
import {
  addTrackingProperties,
  useExperiments,
} from "../../context/experiments";

export enum CustomerProfileLandingSection {
  Travelers = "travelers",
  HotelPreferences = "hotel-preferences",
  FlightPreferences = "flight-preferences",
  PaymentMethods = "payment-methods",
  ContactInfo = "contact-info",
}

const sectionsToShow: (isMobile: boolean) => {
  section: CustomerProfileLandingSection;
  label: string;
  icon: IconName;
  description?: string;
  content?: JSX.Element;
  displayTopBorder: boolean;
}[] = (isMobile) => [
  {
    section: CustomerProfileLandingSection.Travelers,
    label: "Travelers",
    icon: IconName.UserOutline,
    description:
      "Manage traveler details and frequent flyer programs to speed up the booking process. ",
    content: <TravelerSection />,
    displayTopBorder: isMobile ? false : true,
  },
  {
    section: CustomerProfileLandingSection.HotelPreferences,
    label: "Hotel preferences",
    icon: IconName.HotelFunnelIcon,
    description:
      "Manage your hotel preferences to see personalized search results.",
    content: <HotelPreferences />,
    displayTopBorder: false,
  },
  {
    section: CustomerProfileLandingSection.FlightPreferences,
    label: "Flight preferences",
    icon: IconName.FlightFunnelIcon,
    description:
      "Manage your flight preferences to see personalized search results.",
    content: <FlightPreferences />,
    displayTopBorder: false,
  },
  {
    section: CustomerProfileLandingSection.PaymentMethods,
    label: "Payment methods",
    icon: IconName.RewardsAccountBalanceIcon,
    description:
      "Manage your saved payment methods to speed up the booking process.",
    content: <PaymentMethodsSection />,
    displayTopBorder: true,
  },
  {
    section: CustomerProfileLandingSection.ContactInfo,
    label: "Contact information",
    icon: IconName.MobilePhoneOutline,
    description:
      "This information is used to notify you about any updates or changes while traveling. If you’re booking on behalf of another traveler, you can change this information on a per trip basis while checking out.",
    content: <ContactInfo />,
    displayTopBorder: true,
  },
];

export interface ICustomerProfileLandingProps
  extends CustomerProfileLandingConnectorProps,
    RouteComponentProps {}

export const CustomerProfileLanding = ({
  history,
}: ICustomerProfileLandingProps) => {
  const { matchesMobile } = useDeviceTypes();
  const firstName =
    useContext(ClientContext).sessionInfo?.userInfo?.firstName || "";

  const expState = useExperiments();

  const { section } = queryStringParser.parse(history.location.search);

  const headerBreadCrumbLinks = [
    {
      onClick: () => {
        history.goBack ? history.goBack() : history.push(BASE_PATH_HOME);
      },
      label: SEARCH,
    },
    { label: MY_PROFILE },
  ];

  const handleChangeSection = (section?: CustomerProfileLandingSection) => {
    if (section) {
      history.push(`${PATH_HOME}?section=${section}`);
    } else {
      history.push(PATH_HOME);
    }
  };

  const selectedSectionInfo = React.useMemo(
    () =>
      sectionsToShow(matchesMobile).find(
        (sectionToShow) => sectionToShow.section === section
      ),
    [section, matchesMobile]
  );

  React.useEffect(() => {
    if (
      !matchesMobile &&
      (!section ||
        !Object.values(CustomerProfileLandingSection).includes(
          section as CustomerProfileLandingSection
        ))
    ) {
      handleChangeSection(CustomerProfileLandingSection.Travelers);
    } else if (
      matchesMobile &&
      section &&
      !Object.values(CustomerProfileLandingSection).includes(
        section as CustomerProfileLandingSection
      )
    ) {
      handleChangeSection(undefined);
    }
  }, [section, matchesMobile]);

  React.useEffect(() => {
    if (expState.trackingProperties) {
      const profile_section: CustomerProfileEntryProperties["profile_section"] =
        (() => {
          switch (section) {
            case CustomerProfileLandingSection.Travelers:
            default:
              return "travelers";
            case CustomerProfileLandingSection.HotelPreferences:
              return "hotel_preferences";
            case CustomerProfileLandingSection.FlightPreferences:
              return "flight_preferences";
            case CustomerProfileLandingSection.PaymentMethods:
              return "payment_information";
            case CustomerProfileLandingSection.ContactInfo:
              return "contact_information";
          }
        })();

      const properties: CustomerProfileEntryProperties = {
        profile_section,
      };

      trackEvent({
        eventName: CUSTOMER_PROFILE_ENTRY,
        properties: addTrackingProperties(
          expState.trackingProperties,
          properties
        ),
      });
    }
  }, [expState.trackingProperties]);

  return (
    <Box
      className={clsx("customer-profile-landing-root", {
        mobile: matchesMobile,
      })}
    >
      {(matchesMobile ? !section : true) && (
        <SecondaryHeader
          title={getTitle(firstName)}
          subtitle={SUBTITLE}
          breadCrumbLinks={matchesMobile ? undefined : headerBreadCrumbLinks}
          className="travel-wallet"
        />
      )}
      <Box
        className={clsx("customer-profile-landing-content-container", {
          "has-section": !!section,
        })}
      >
        {(matchesMobile ? !section : true) && (
          <Box
            className="customer-profile-landing-nav-tabs"
            role="tablist"
            aria-orientation="vertical"
          >
            {sectionsToShow(matchesMobile).map((sectionToShow) => (
              <NavLink
                id={`${sectionToShow.section}-tab`}
                key={`${sectionToShow.section}-tab`}
                role="tab"
                aria-controls="customer-profile-landing-tabpanel"
                aria-selected={sectionToShow.section === section}
                to={`${PATH_HOME}?section=${sectionToShow.section}`}
                className="customer-profile-landing-nav-tab"
                isActive={() => sectionToShow.section === section}
                tabIndex={sectionToShow.section !== section ? -1 : undefined}
              >
                <Icon className="section-tab-icon" name={sectionToShow.icon} />
                <Typography className="section-tab-label">
                  {sectionToShow.label}
                </Typography>
                <Icon name={IconName.RightChevronIcon} />
              </NavLink>
            ))}
          </Box>
        )}

        {(matchesMobile ? !!section : true) && (
          <Box
            className={clsx("customer-profile-landing-tab-content", section)}
            id="customer-profile-landing-tabpanel"
            role="tabpanel"
            aria-labelledby={`${section}-tab`}
          >
            {matchesMobile && (
              <Box className="customer-profile-section-mobile-nav">
                <ButtonWrap
                  className="back-button"
                  onClick={() => handleChangeSection(undefined)}
                >
                  <Icon
                    name={IconName.RightChevronIcon}
                    className="back-button-icon"
                  />
                </ButtonWrap>

                <Typography className="customer-profile-section-mobile-nav-heading">
                  {selectedSectionInfo?.label}
                </Typography>

                <Box />
              </Box>
            )}
            {!matchesMobile && (
              <Typography
                variant="h2"
                className="customer-profile-landing-tab-content-title"
              >
                {selectedSectionInfo?.icon && (
                  <Icon name={selectedSectionInfo?.icon} />
                )}
                {selectedSectionInfo?.label}
              </Typography>
            )}
            <Typography
              variant="body2"
              className={clsx(
                "customer-profile-landing-tab-content-description",
                { mobile: matchesMobile }
              )}
            >
              {selectedSectionInfo?.description}
            </Typography>
            {selectedSectionInfo?.displayTopBorder && <hr />}
            {selectedSectionInfo?.content}
          </Box>
        )}
      </Box>
      {(matchesMobile ? !section : true) && (
        <Box className="customer-profile-landing-footer">
          <Box className="customer-profile-landing-footer-content">
            <Icon name={IconName.FlipFlopsWithBorder} />
            <Typography className="footer-primary-text">
              {FOOTER_PRIMARY_TEXT}
            </Typography>
            <Typography className="footer-secondary-text">
              {FOOTER_SECONDARY_TEXT}
            </Typography>
            <Link to={BASE_PATH_HOME} className="footer-cta">
              {FOOTER_CTA_TEXT}
            </Link>
          </Box>
        </Box>
      )}
    </Box>
  );
};
