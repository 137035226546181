import { IStoreState } from "../../../../reducers/types";
import { createSelector } from "reselect";

export const getUserPassengers = (state: IStoreState) =>
  state.preferences.userPassengers;

export const getUserPassengersCallState = (state: IStoreState) =>
  state.preferences.userPassengerCallState;

export const getUserHotelPreferences = (state: IStoreState) =>
  state.preferences.userHotelPreferences;

export const getUserHotelPreferencesCallState = (state: IStoreState) =>
  state.preferences.userHotelPreferencesCallState;

export const getUserContactInfo = (state: IStoreState) =>
  state.preferences.userContactInfo;

export const getUserContactInfoCallState = (state: IStoreState) =>
  state.preferences.userContactInfoCallState;

export const getUpdateUserHotelPreferencesCallState = (state: IStoreState) =>
  state.preferences.updateUserHotelPreferencesCallState;

export const getUserFlightPreferences = (state: IStoreState) =>
  state.preferences.userFlightPreferences;

export const getUserFlightPreferencesCallState = (state: IStoreState) =>
  state.preferences.userFlightPreferencesCallState;

export const getUpdateUserFlightPreferencesCallState = (state: IStoreState) =>
  state.preferences.updateUserFlightPreferencesCallState;
export const getPaymentMethods = (state: IStoreState) =>
  state.preferences.paymentMethods;

export const getListPaymentMethodsCallState = (state: IStoreState) =>
  state.preferences.listPaymentMethodCallState;

export const getDeletePaymentMethodCallState = (state: IStoreState) =>
  state.preferences.deletePaymentMethodCallState;

export const getVerifyPaymentMethodCallState = (state: IStoreState) =>
  state.preferences.verifyPaymentMethodCallState;

export const getHasUserSetHotelPreferences = createSelector(
  getUserHotelPreferences,
  (userHotelPreferences): boolean => {
    return (
      !!userHotelPreferences?.amenities.length ||
      !!userHotelPreferences?.starRatings.length ||
      !!userHotelPreferences?.freeCancellationOnly
    );
  }
);

export const getHasUserSetFlightPreferences = createSelector(
  getUserFlightPreferences,
  (userFlightPreferences): boolean => {
    if (userFlightPreferences) {
      return (
        !!userFlightPreferences.airlines.length ||
        !!userFlightPreferences.homeAirport ||
        userFlightPreferences.nonStopOnly ||
        Object.values(userFlightPreferences.fareClasses).some(
          (val) => val == true
        )
      );
    }
    return false;
  }
);

export const getVerifyPaymentMethodResults = (state: IStoreState) =>
  state.preferences.verifyPaymentMethodResult;
