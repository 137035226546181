import { all } from "redux-saga/effects";
import { sagas as rewards } from "../modules/rewards";
import { sagas as preferences } from "../modules/preferences";
export function* rootSaga() {
  yield all([
    rewards.watchFetchRewardsAccounts(),
    preferences.watchFetchUserContactInfo(),
    preferences.watchFetchUserPassengers(),
    preferences.watchFetchUserHotelPreferences(),
    preferences.watchFetchUserFlightPreferences(),
    preferences.watchUpdateUserPassengers(),
    preferences.watchUpdateUserHotelPreferences(),
    preferences.watchUpdateUserFlightPreferences(),
    preferences.watchDeleteUserPassenger(),
    preferences.watchListPaymentMethods(),
    preferences.watchVerifyPaymentMethod(),
    preferences.watchDeletePaymentMethod(),
  ]);
}
