import axios from "axios";
import { userFlightPreferencesPath } from "../paths";
import { UserFlightPreferencesPayload } from "redmond";
import { config } from "../../config";

export interface UpdateUserFlightPreferencesPayload {
  value: {};
  Response: "Success" | "Failed";
}

export const updateUserFlightPreferences = (
  body: UserFlightPreferencesPayload
): Promise<UpdateUserFlightPreferencesPayload> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(userFlightPreferencesPath, body, {
        baseURL: config.baseURL,
      });

      const responseBody: UpdateUserFlightPreferencesPayload = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });
