import { connect, ConnectedProps } from "react-redux";

import { FlightPreferences } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  fetchUserFlightPreferences,
  resetUpdateUserFlightPreferencesCallState,
  updateUserFlightPreferences,
} from "../../../../modules/preferences/actions/actions";
import {
  getHasUserSetFlightPreferences,
  getUpdateUserFlightPreferencesCallState,
  getUserFlightPreferences,
  getUserFlightPreferencesCallState,
} from "../../../../modules/preferences/reducer";
import { CallState } from "redmond";

const mapStateToProps = (state: IStoreState) => {
  const updateUserFlightPreferencesCallstate =
    getUpdateUserFlightPreferencesCallState(state);
  return {
    userFlightPreferences: getUserFlightPreferences(state),
    isLoadingUserFlightPreferences:
      getUserFlightPreferencesCallState(state) === CallState.InProcess,
    isUpdatingUserFlightPreferences:
      updateUserFlightPreferencesCallstate === CallState.InProcess,
    updateUserFlightPreferencesCallstate,
    userHasSetFlightPreferences: getHasUserSetFlightPreferences(state),
  };
};

const mapDispatchToProps = {
  fetchUserFlightPreferences,
  updateUserFlightPreferences,
  resetUpdateUserFlightPreferencesCallState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightPreferencesConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightPreferences = connector(FlightPreferences);
