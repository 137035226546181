import {
  CallState,
  IPerson,
  Payment,
  PaymentVerifyResultEnum,
  PersonId,
  UserFlightPreferencesPayload,
  UserHotelPreferencesPayload,
} from "redmond";

import * as actionTypes from "./constants";
import { UserContactInfoResponse } from "../../../api/v1/user/fetchUserContactInfo";
import { ToggleActions } from "halifax";

export const fetchUserPassengers = (): IFetchUserPassengers => ({
  type: actionTypes.FETCH_USER_PASSENGERS,
});

export interface IFetchUserPassengers {
  type: actionTypes.FETCH_USER_PASSENGERS;
}

export const updateUserPassenger = (
  traveler: IPerson,
  hideProfileAction: ToggleActions,
  entryPoint?: string,
  updatePassport?: boolean,
  isNewTraveler?: boolean
): IUpdateUserPassenger => ({
  type: actionTypes.UPDATE_USER_PASSENGER,
  traveler,
  hideProfileAction,
  entryPoint,
  updatePassport,
  isNewTraveler,
});

export interface IUpdateUserPassenger {
  type: actionTypes.UPDATE_USER_PASSENGER;
  traveler: IPerson;
  hideProfileAction: ToggleActions;
  entryPoint?: string;
  updatePassport?: boolean;
  isNewTraveler?: boolean;
}

export const deleteUserPassenger = (
  travelerId: PersonId
): IDeleteUserPassenger => ({
  type: actionTypes.DELETE_USER_PASSENGER,
  travelerId,
});

export interface IDeleteUserPassenger {
  type: actionTypes.DELETE_USER_PASSENGER;
  travelerId: PersonId;
}

export const setUserPassengers = (args: {
  userPassengerCallState: CallState;
  userPassengers: IPerson[];
}): ISetUserPassengers => ({
  type: actionTypes.SET_USER_PASSENGERS,
  ...args,
});

export interface ISetUserPassengers {
  type: actionTypes.SET_USER_PASSENGERS;
  userPassengerCallState: CallState;
  userPassengers: IPerson[];
}

export const setUserPassengersCallStateFailed =
  (): ISetUserPassengersCallStateFailed => ({
    type: actionTypes.SET_USER_PASSENGERS_CALL_STATE_FAILED,
  });

export interface ISetUserPassengersCallStateFailed {
  type: actionTypes.SET_USER_PASSENGERS_CALL_STATE_FAILED;
}
export const fetchUserHotelPreferences = (): IFetchUserHotelPreferences => ({
  type: actionTypes.FETCH_USER_HOTEL_PREFERENCES,
});

export interface IFetchUserHotelPreferences {
  type: actionTypes.FETCH_USER_HOTEL_PREFERENCES;
}

export const updateUserHotelPreferences = (
  hotelPreferences: UserHotelPreferencesPayload
): IUpdateUserHotelPreferences => ({
  type: actionTypes.UPDATE_USER_HOTEL_PREFERENCES,
  hotelPreferences,
});

export interface IUpdateUserHotelPreferences {
  type: actionTypes.UPDATE_USER_HOTEL_PREFERENCES;
  hotelPreferences: UserHotelPreferencesPayload;
}

export const setUserHotelPreferences = (args: {
  hotelPreferences: UserHotelPreferencesPayload;
}): ISetUserHotelPreferences => ({
  type: actionTypes.SET_USER_HOTEL_PREFERENCES,
  hotelPreferences: args.hotelPreferences,
});

export interface ISetUserHotelPreferences {
  type: actionTypes.SET_USER_HOTEL_PREFERENCES;
  hotelPreferences: UserHotelPreferencesPayload;
}

export const setUserHotelPreferencesCallStateFailed =
  (): ISetUserHotelPreferencesCallStateFailed => ({
    type: actionTypes.SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED,
  });

export interface ISetUserHotelPreferencesCallStateFailed {
  type: actionTypes.SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED;
}

export const setUpdateUserHotelPreferencesCallStateFailed =
  (): ISetUpdateUserHotelPreferencesCallStateFailed => ({
    type: actionTypes.SET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED,
  });

export interface ISetUpdateUserHotelPreferencesCallStateFailed {
  type: actionTypes.SET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED;
}

export const setUpdateUserHotelPreferencesCallStateSuccess =
  (): ISetUpdateUserHotelPreferencesCallStateSuccess => ({
    type: actionTypes.SET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE_SUCCESS,
  });

export interface ISetUpdateUserHotelPreferencesCallStateSuccess {
  type: actionTypes.SET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE_SUCCESS;
}

export const resetUpdateUserHotelPreferencesCallState =
  (): IResetUpdateUserHotelPreferencesCallState => ({
    type: actionTypes.RESET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE,
  });

export interface IResetUpdateUserHotelPreferencesCallState {
  type: actionTypes.RESET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE;
}

export const fetchUserContactInfo = (): IFetchUserContactInfo => ({
  type: actionTypes.FETCH_USER_CONTACT_INFO,
});

export interface IFetchUserContactInfo {
  type: actionTypes.FETCH_USER_CONTACT_INFO;
}

export const setUserContactInfo = (args: {
  userContactInfo: UserContactInfoResponse;
}): ISetUserContactInfo => ({
  type: actionTypes.SET_USER_CONTACT_INFO,
  userContactInfo: args.userContactInfo,
});

export interface ISetUserContactInfo {
  type: actionTypes.SET_USER_CONTACT_INFO;
  userContactInfo: UserContactInfoResponse;
}

export const setUserContactInfoCallStateFailed =
  (): ISetUserContactInfoCallStateFailed => ({
    type: actionTypes.SET_USER_CONTACT_INFO_CALL_STATE_FAILED,
  });

export interface ISetUserContactInfoCallStateFailed {
  type: actionTypes.SET_USER_CONTACT_INFO_CALL_STATE_FAILED;
}

export const fetchUserFlightPreferences = (): IFetchUserFlightPreferences => ({
  type: actionTypes.FETCH_USER_FLIGHT_PREFERENCES,
});

export interface IFetchUserFlightPreferences {
  type: actionTypes.FETCH_USER_FLIGHT_PREFERENCES;
}

export const updateUserFlightPreferences = (
  flightPreferences: UserFlightPreferencesPayload
): IUpdateUserFlightPreferences => ({
  type: actionTypes.UPDATE_USER_FLIGHT_PREFERENCES,
  flightPreferences,
});

export interface IUpdateUserFlightPreferences {
  type: actionTypes.UPDATE_USER_FLIGHT_PREFERENCES;
  flightPreferences: UserFlightPreferencesPayload;
}

export const setUserFlightPreferences = (args: {
  flightPreferences: UserFlightPreferencesPayload;
}): ISetUserFlightPreferences => ({
  type: actionTypes.SET_USER_FLIGHT_PREFERENCES,
  flightPreferences: args.flightPreferences,
});

export interface ISetUserFlightPreferences {
  type: actionTypes.SET_USER_FLIGHT_PREFERENCES;
  flightPreferences: UserFlightPreferencesPayload;
}

export const setUserFlightPreferencesCallStateFailed =
  (): ISetUserFlightPreferencesCallStateFailed => ({
    type: actionTypes.SET_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED,
  });

export interface ISetUserFlightPreferencesCallStateFailed {
  type: actionTypes.SET_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED;
}

export const setUpdateUserFlightPreferencesCallStateFailed =
  (): ISetUpdateUserFlightPreferencesCallStateFailed => ({
    type: actionTypes.SET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED,
  });

export interface ISetUpdateUserFlightPreferencesCallStateFailed {
  type: actionTypes.SET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED;
}

export const setUpdateUserFlightPreferencesCallStateSuccess =
  (): ISetUpdateUserFlightPreferencesCallStateSuccess => ({
    type: actionTypes.SET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE_SUCCESS,
  });

export interface ISetUpdateUserFlightPreferencesCallStateSuccess {
  type: actionTypes.SET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE_SUCCESS;
}

export const resetUpdateUserFlightPreferencesCallState =
  (): IResetUpdateUserFlightPreferencesCallState => ({
    type: actionTypes.RESET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE,
  });

export interface IResetUpdateUserFlightPreferencesCallState {
  type: actionTypes.RESET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE;
}

export const fetchPaymentMethod = (paymentId: string): IFetchPaymentMethod => ({
  type: actionTypes.FETCH_PAYMENT_METHOD,
  paymentId,
});

export interface IFetchPaymentMethod {
  type: actionTypes.FETCH_PAYMENT_METHOD;
  paymentId: string;
}

export const listPaymentMethods = (): IListPaymentMethods => ({
  type: actionTypes.LIST_PAYMENT_METHODS,
});

export interface IListPaymentMethods {
  type: actionTypes.LIST_PAYMENT_METHODS;
}

export const verifyPaymentMethod = (
  payload: IVerifyPaymentMethodArgs,
  accountId: string,
  isVCN: boolean
): IVerifyPaymentMethod => ({
  type: actionTypes.VERIFY_PAYMENT_METHOD,
  payload,
  accountId,
  isVCN,
});

export interface IVerifyPaymentMethod {
  type: actionTypes.VERIFY_PAYMENT_METHOD;
  payload: IVerifyPaymentMethodArgs;
  accountId: string;
  isVCN: boolean;
}

export interface IVerifyPaymentMethodArgs {
  token: string;
}

export const setPaymentMethods = (args: {
  paymentMethods: Payment[];
}): ISetPaymentMethods => ({
  type: actionTypes.SET_PAYMENT_METHODS,
  ...args,
});

export interface ISetPaymentMethods {
  type: actionTypes.SET_PAYMENT_METHODS;
  paymentMethods: Payment[];
}

export interface ISetPaymentMethods {
  type: actionTypes.SET_PAYMENT_METHODS;
  paymentMethods: Payment[];
}

export const deletePaymentMethod = (args: {
  paymentId: string;
}): IDeletePaymentMethod => ({
  type: actionTypes.DELETE_PAYMENT_METHOD,
  ...args,
});

export interface IDeletePaymentMethod {
  type: actionTypes.DELETE_PAYMENT_METHOD;
  paymentId: string;
}

export const deletePaymentMethodCallStateFailed =
  (): IDeletePaymentMethodCallStateFailed => ({
    type: actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_FAILED,
  });

export interface IDeletePaymentMethodCallStateFailed {
  type: actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_FAILED;
}

export const deletePaymentMethodCallStateSuccess =
  (): IDeletePaymentMethodCallStateSuccess => ({
    type: actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS,
  });

export interface IDeletePaymentMethodCallStateSuccess {
  type: actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS;
}

export const setPaymentMethodsCallStateFailed =
  (): ISetPaymentMethodsCallStateFailed => ({
    type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED,
  });

export interface ISetPaymentMethodsCallStateFailed {
  type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED;
}

export const setPaymentMethodCallStateFailed =
  (): ISetPaymentMethodCallStateFailed => ({
    type: actionTypes.SET_PAYMENT_METHOD_CALL_STATE_FAILED,
  });

export interface ISetPaymentMethodCallStateFailed {
  type: actionTypes.SET_PAYMENT_METHOD_CALL_STATE_FAILED;
}

export const verifyPaymentMethodCallStateFailed = (
  result: PaymentVerifyResultEnum
): IVerifyPaymentMethodCallStateFailed => ({
  type: actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED,
  result,
});

export interface IVerifyPaymentMethodCallStateFailed {
  type: actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED;
  result: PaymentVerifyResultEnum;
}

export const verifyPaymentMethodCallStateSuccess =
  (): IVerifyPaymentMethodCallStateSuccess => ({
    type: actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS,
  });

export interface IVerifyPaymentMethodCallStateSuccess {
  type: actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS;
}

export type CustomerProfilePreferencesActions =
  | IFetchUserPassengers
  | IUpdateUserPassenger
  | IDeleteUserPassenger
  | ISetUserPassengers
  | ISetUserPassengersCallStateFailed
  | IFetchUserHotelPreferences
  | IUpdateUserHotelPreferences
  | ISetUserHotelPreferences
  | ISetUserHotelPreferencesCallStateFailed
  | ISetUpdateUserHotelPreferencesCallStateFailed
  | ISetUpdateUserHotelPreferencesCallStateSuccess
  | IFetchUserContactInfo
  | ISetUserContactInfo
  | ISetUserContactInfoCallStateFailed
  | IFetchUserFlightPreferences
  | IUpdateUserFlightPreferences
  | ISetUserFlightPreferences
  | ISetUserFlightPreferencesCallStateFailed
  | ISetUpdateUserFlightPreferencesCallStateFailed
  | ISetUpdateUserFlightPreferencesCallStateSuccess
  | IListPaymentMethods
  | IVerifyPaymentMethod
  | IVerifyPaymentMethodCallStateSuccess
  | IVerifyPaymentMethodCallStateFailed
  | IDeletePaymentMethod
  | IDeletePaymentMethodCallStateSuccess
  | IDeletePaymentMethodCallStateFailed
  | ISetPaymentMethods
  | ISetPaymentMethodsCallStateFailed
  | ISetPaymentMethodCallStateFailed
  | IResetUpdateUserFlightPreferencesCallState
  | IResetUpdateUserHotelPreferencesCallState;
