import React from "react";

export const PHONE_NUMBER_HEADING_TEXT = "Phone number";
export const EMAIL_HEADING_TEXT = "Email";

export const INFO_TEXT = (
  <>
    This information is associated with your Capital One account. To edit this
    information, please visit{" "}
    <a href="https://www.capitalone.com/" target="_blank">
      capitalone.com
    </a>{" "}
    or call <a href="tel:+18002274825">1-800-227-4825</a>.
  </>
);
