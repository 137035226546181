import { put } from "redux-saga/effects";
import { actions } from "../actions";
import { CustomerDetails, SessionInfo } from "redmond";
import { fetchCustomerDetails } from "../../../api/v1/customer/fetchCustomerDetails";
import fetchUserInfo from "../../../api/v1/user/fetchUserInfo";

export function* fetchUserContactInfoSaga(_: actions.IFetchUserContactInfo) {
  try {
    const customerDetails: CustomerDetails = yield fetchCustomerDetails();
    const userInfo: SessionInfo = yield fetchUserInfo();

    yield put(
      actions.setUserContactInfo({
        userContactInfo: {
          emailAddress: userInfo.userInfo.email || "",
          phoneNumber: customerDetails.phoneNumber || "",
        },
      })
    );
  } catch (e) {
    yield put(actions.setUserContactInfoCallStateFailed());
  }
}
