import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { Experiment, ExperimentVariant, ExperimentState } from "redmond";
import { fetchActiveExperiments } from "../api/v1/experiments/fetchExperiments";

// Active Experiments
// export const EXAMPLE = "c1-example";

export const CUSTOMER_PROFILE_EXPERIMENT = "c1-marketplace-customer-profile";

// Default variants
export const CONTROL = "control";
export const AVAILABLE = "available";

// Variants for c1-marketplace-customer-profile
export const DEFAULT_OFF = "default-off";
export const DEFAULT_ON = "default-on";
export const CUSTOMER_PROFILE_VARIANTS = [
  CONTROL,
  DEFAULT_OFF,
  DEFAULT_ON,
] as const;
export type CustomerProfileVariantType =
  | typeof CONTROL
  | typeof DEFAULT_OFF
  | typeof DEFAULT_ON;

const defaultInitializer = (): ExperimentState => {
  return {
    experiments: [],
    trackingProperties: undefined,
  };
};

export const ExperimentsContext = createContext<ExperimentState | undefined>(
  undefined
);

// readonly (together with const restriction below) ensures typeof supportedVariants[number]
// can be recognized by transpiler as an array of constants
// [string, ...string[]] ensures array is not empty.
export function getExperimentVariantCustomVariants<
  T extends readonly [string, ...string[]]
>(
  experiments: Array<Experiment>,
  experimentId: string,
  // List of supported variants. If the variant retreived is not recognized by the code, the code will fall back to the first variant.
  // Note the input list needs to be declared as const e.g. const DEFAULT_VARIANTS = [CONTROL, AVAILABLE] as const;
  supportedVariants: T
): (typeof supportedVariants)[number] {
  const experiment = experiments?.find((exp) => exp.id === experimentId);

  if (experiment) {
    if (supportedVariants.includes(experiment.variant)) {
      return experiment.variant;
    } else {
      return supportedVariants[0];
    }
  } else {
    return supportedVariants[0];
  }
}

export function getExperimentVariant(
  experiments: Array<Experiment> = [],
  experimentId: String,
  defaultExperimentVariant: ExperimentVariant = ExperimentVariant.CONTROL
): ExperimentVariant {
  const experiment = experiments?.find((exp) => {
    if (exp.id === experimentId) {
      return exp;
    }
    return null;
  });

  return (experiment?.variant as ExperimentVariant) ?? defaultExperimentVariant;
}

export function useExperiments(): ExperimentState {
  const ctx = useContext(ExperimentsContext);
  if (!ctx) throw new Error(`must be used within a ExperimentsProvider`);
  return ctx;
}

export function addTrackingProperties(
  trackingProperties: Map<string, string> | undefined,
  properties?: any
): unknown {
  if (!properties) {
    properties = {};
  }
  if (trackingProperties) {
    properties["experiments"] = Object.keys(trackingProperties).map(
      (ex) => `${ex}_${trackingProperties![ex]}`
    );
  }
  return properties;
}

export enum ActiveExperiments {}

type ExperimentsProviderProps = {
  initState?: ExperimentState;
};

const ExperimentsProvider: FC<ExperimentsProviderProps & PropsWithChildren> = ({
  initState = defaultInitializer(),
  children,
}) => {
  // TODO: use tracking reducers
  const [state, setState] = useState(initState);

  useEffect(() => {
    if (!initState?.experiments.length) {
      const fetchExperiments = async () => {
        await fetchActiveExperiments().then((result: ExperimentState) => {
          setState(result);
        });
      };
      fetchExperiments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExperimentsContext.Provider value={state}>
      {children}
    </ExperimentsContext.Provider>
  );
};

export default ExperimentsProvider;
