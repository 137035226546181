import axios from "axios";
import { IPerson } from "redmond";

import { airBookingApi } from "@b2bportal/air-booking-api";

export const updateUserPassenger = (
  body: IUpdateUserPassengerRequest
): Promise<IUpdateUserPassengerResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await airBookingApi(
        axios as any
      ).apiV0FlightPassengersUpdatePut(body);

      const responseBody: IUpdateUserPassengerResponse = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });

export interface IUpdateUserPassengerRequest {
  person: IPerson;
}

export interface IUpdateUserPassengerResponse {}
