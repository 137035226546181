import { put } from "redux-saga/effects";
import { actions } from "../actions";
import { UserHotelPreferencesPayload } from "redmond";
import {
  UpdateUserHotelPreferencesPayload,
  updateUserHotelPreferences,
} from "../../../api/v1/user/updateUserHotelPreferences";
import fetchUserHotelPreferences from "../../../api/v1/user/fetchUserHotelPreferences";

export function* updateUserHotelPreferencesSaga(
  action: actions.IUpdateUserHotelPreferences
) {
  try {
    const res: UpdateUserHotelPreferencesPayload =
      yield updateUserHotelPreferences(action.hotelPreferences);

    if (res.Response !== "Success") {
      yield put(actions.setUpdateUserHotelPreferencesCallStateFailed());
      throw new Error("Failed to update user hotel preferences");
    }

    yield put(actions.setUpdateUserHotelPreferencesCallStateSuccess());

    const newSavedPreferences: UserHotelPreferencesPayload =
      yield fetchUserHotelPreferences();

    yield put(
      actions.setUserHotelPreferences({ hotelPreferences: newSavedPreferences })
    );
  } catch (e) {
    console.error(e);
    yield put(actions.setUserPassengersCallStateFailed());
  }
}
