import { put } from "redux-saga/effects";
import { actions } from "../actions";
import {
  NEW_PAYMENT_METHOD_ADDED,
  NewPaymentMethodAddedProperties,
  PaymentVerifyResult,
  PaymentVerifyResultEnum,
} from "redmond";
import { verifyPaymentMethod } from "../../../api/v1/payment-methods/verifyPaymentMethod";
import { trackEvent } from "../../../api/v1/analytics/trackEvent";
import { addTrackingProperties } from "../../../context/experiments";

export function* verifyPaymentMethodSaga({
  payload,
}: actions.IVerifyPaymentMethod) {
  try {
    const result: PaymentVerifyResult = yield verifyPaymentMethod(payload);
    switch (result.PaymentVerifyResult) {
      case PaymentVerifyResultEnum.CardDeclined:
        yield put(
          actions.verifyPaymentMethodCallStateFailed(result.PaymentVerifyResult)
        );
        break;
      case PaymentVerifyResultEnum.ExcessiveUserActivity:
        yield put(
          actions.verifyPaymentMethodCallStateFailed(result.PaymentVerifyResult)
        );
        break;
      case PaymentVerifyResultEnum.GenericError:
        yield put(
          actions.verifyPaymentMethodCallStateFailed(result.PaymentVerifyResult)
        );
        break;
      case PaymentVerifyResultEnum.Success:
        yield put(actions.verifyPaymentMethodCallStateSuccess());
        yield put(actions.listPaymentMethods());

        trackEvent({
          eventName: NEW_PAYMENT_METHOD_ADDED,
          properties: addTrackingProperties(undefined, {
            entry_type: "customer_profile",
          } as NewPaymentMethodAddedProperties),
        });
        return;

      default:
        yield put(
          actions.verifyPaymentMethodCallStateFailed(result.PaymentVerifyResult)
        );
        break;
    }
  } catch (e) {
    yield put(
      actions.verifyPaymentMethodCallStateFailed(
        PaymentVerifyResultEnum.GenericError
      )
    );
  }
}
