export const apiVersionPrefix: string = "/api/v0";

export const analyticsApiPrefix: string = `${apiVersionPrefix}/tracking`;
export const analyticsEventApi: string = `${analyticsApiPrefix}/event`;
export const experimentsApiPrefix: string = `${apiVersionPrefix}/experiments`;
export const rewardsApiPrefix: string = `${apiVersionPrefix}/rewards`;
export const fetchUserInfoPath: string = `${apiVersionPrefix}/userInfo`;

export const userPreferenceApiPrefix: string = `${apiVersionPrefix}/user/preference`;

export const userFlightPreferencesPath: string = `${userPreferenceApiPrefix}/flight`;
export const userHotelPreferencesPath: string = `${userPreferenceApiPrefix}/hotel`;
export const userContactInfoPath: string = `${userPreferenceApiPrefix}/contactInformation`;

export const passengersApiPrefix: string = `${apiVersionPrefix}/passengers`;
export const deleteUserPassengerPath: string = `${passengersApiPrefix}/delete`;

export const fetchLocationsPath = `${apiVersionPrefix}/autocomplete/location`;
export const paymentMethodsApiPrefix: string = `${apiVersionPrefix}/paymentMethods`;

export const customerDetailsApiPath: string = `${apiVersionPrefix}/customer/details`;
