import { put } from "redux-saga/effects";
import { actions } from "../actions";
import { deletePaymentMethod } from "../../../api/v1/payment-methods/deletePaymentMethod";

export function* deletePaymentMethodSaga({
  paymentId,
}: actions.IDeletePaymentMethod) {
  try {
    yield deletePaymentMethod({ paymentId });
    yield put(actions.deletePaymentMethodCallStateSuccess());
    yield put(actions.listPaymentMethods());
  } catch (e) {
    yield put(actions.deletePaymentMethodCallStateFailed());
  }
}
