import React, { useContext, useEffect } from "react";
import { GenericBanner, useDeviceTypes } from "halifax";
import { RouterProps } from "react-router-dom";
import { B2B_PORTAL_BANNER_CONTAINER_ID } from "redmond";
import * as constants from "./constants";
import { ClientContext } from "../../../../App";

import { RewardsBannerConnectorProps } from "./container";
import { PATH_HOME } from "../../../../utils/paths";

export interface IRewardsBannerProps
  extends RewardsBannerConnectorProps,
    RouterProps {}

export const RewardsBanner = (props: IRewardsBannerProps) => {
  const { history, largestValueAccount: account, fetchRewardsAccounts } = props;

  useEffect(() => {
    fetchRewardsAccounts();
  }, []);

  const { matchesMobile } = useDeviceTypes();
  const firstName =
    useContext(ClientContext).sessionInfo?.userInfo?.firstName || "";

  let contentText = "";

  const {
    earn,
    lastFour = "",
    productDisplayName: productId = "",
    rewardsBalance,
  } = account || {};
  const { currency, currencyDescription } = rewardsBalance || {};
  const {
    hotelsMultiplier: earnRate = "",
    flightsMultiplier: flightEarnRate = "",
  } = earn || {};

  //if no earn multiplier do not display a banner or if its less than 0
  if (!account || !account?.earn?.hotelsMultiplier || earnRate <= 0) {
    return null;
  }

  // TODO: handle texts properly once the backend is ready
  switch (history.location.pathname) {
    case PATH_HOME.slice(0, -1):
    case PATH_HOME:
      if (!matchesMobile) {
        contentText = constants.HOME_PAGE_BANNER_TEXT({
          firstName,
          earnRate,
          flightEarnRate,
          productId,
          lastFour,
          currency: currencyDescription || currency,
        });
      }
      // hide the banner on mobile search workflow
      else {
        contentText = "";
      }
      break;
  }

  if (!contentText) {
    return null;
  }

  return (
    <GenericBanner
      className="rewards-banner-root"
      content={contentText}
      containerId={B2B_PORTAL_BANNER_CONTAINER_ID}
      isMobile={matchesMobile}
    />
  );
};
