import axios from "axios";
import { userHotelPreferencesPath } from "../paths";
import { UserHotelPreferencesPayload } from "redmond";
import { config } from "../../config";

export interface UpdateUserHotelPreferencesPayload {
  value: {};
  Response: "Success" | "Failed";
}

export const updateUserHotelPreferences = (
  body: UserHotelPreferencesPayload
): Promise<UpdateUserHotelPreferencesPayload> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(userHotelPreferencesPath, body, {
        baseURL: config.baseURL,
      });

      const responseBody: UpdateUserHotelPreferencesPayload = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });
