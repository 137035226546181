import { FareclassShelfBrandName } from "redmond";

export const RESET_CTA_TEXT = "Clear preferences";
export const SAVE_CTA_TEXT = "Save preferences";
export const SAVE_CTA_ACTIVE_TEXT = "Saving...";

export const getFareClassLabel = (fareClassOption: FareclassShelfBrandName) => {
  return (
    fareClassOption[0].toUpperCase() + fareClassOption.slice(1).toLowerCase()
  );
};
