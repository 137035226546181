import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { FlightPreferencesConnectorProps } from "./container";
import { debounce } from "lodash";
import React from "react";
import clsx from "clsx";
import {
  ActionLink,
  B2BSearchAutocomplete,
  B2BSpinner,
  ButtonWrap,
  CloseButtonIcon,
  Icon,
  IconName,
  MobilePopoverCard,
  MobileSearchFieldButton,
  UserPreferencesSavedBanner,
  useDeviceTypes,
  usePrevious,
  FareClassInfoModal,
} from "halifax";
import {
  UserFlightPreferencesPayload,
  AirlineOpenEnum,
  ILocationQueryLabel,
  LocationQueryEnum,
  ITripTerminus,
  FareclassShelfBrandName,
  AirlineCode,
  CallState,
  FareclassOptionFilter,
  SAVED_FLIGHT_PREFERENCES,
} from "redmond";
import { isEqual } from "lodash-es";
import {
  RESET_CTA_TEXT,
  SAVE_CTA_ACTIVE_TEXT,
  SAVE_CTA_TEXT,
  getFareClassLabel,
} from "./textConstants";
import "./styles.scss";
import fetchLocations from "../../../../api/v1/user/fetchLocations";
import { MobileLocationSearchModal } from "./components";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import {
  addTrackingProperties,
  useExperiments,
} from "../../../../context/experiments";

export interface FlightPreferencesProps
  extends FlightPreferencesConnectorProps {}

const allFareClassOptions = [
  "basic",
  "standard",
  "enhanced",
  "premium",
  "luxury",
] as FareclassShelfBrandName[];

export interface IAirlineOptions {
  label: AirlineCode;
  value: AirlineOpenEnum;
  icon?: JSX.Element;
  tooltip?: JSX.Element;
}

const allAirlineOptions: IAirlineOptions[] = [
  { value: AirlineOpenEnum.AA, label: "American Airlines" },
  { value: AirlineOpenEnum.DL, label: "Delta" },
  { value: AirlineOpenEnum.UA, label: "United" },
  { value: AirlineOpenEnum.NK, label: "Spirit" },
  { value: AirlineOpenEnum.B6, label: "Jetblue" },
  { value: AirlineOpenEnum.F9, label: "Frontier" },
  {
    value: AirlineOpenEnum.ExcludeLcc,
    label: "Exclude Low Cost Carriers",
    tooltip: (
      <>
        Low cost carriers include <strong>Spirit</strong>,{" "}
        <strong>JetBlue</strong> and <strong>Frontier</strong>.
      </>
    ),
  },
];

const allStopOptions = [
  { value: "Any number of stops", label: "Any number of stops" },
  { value: "Nonstop only", label: "Nonstop only" },
];

export const FlightPreferences = (props: FlightPreferencesProps) => {
  const {
    fetchUserFlightPreferences,
    updateUserFlightPreferences,
    userFlightPreferences,
    isLoadingUserFlightPreferences,
    isUpdatingUserFlightPreferences,
    updateUserFlightPreferencesCallstate,
    userHasSetFlightPreferences,
    resetUpdateUserFlightPreferencesCallState,
  } = props;
  //   const { homeAirport, fareClasses, airlines, nonStopOnly } =
  //     userFlightPreferences;

  const { matchesMobile } = useDeviceTypes();

  const expState = useExperiments();

  const [localUserFlightPreferences, setLocalUseFlightPreferences] =
    React.useState<UserFlightPreferencesPayload>({
      homeAirport: userFlightPreferences.homeAirport,
      fareClasses: { ...userFlightPreferences.fareClasses },
      airlines: userFlightPreferences.airlines,
      nonStopOnly: userFlightPreferences.nonStopOnly,
    });

  const {
    homeAirport: localHomeAirport,
    fareClasses: localFareClasses,
    airlines: localAirlines,
    nonStopOnly: localNonStopOnly,
  } = localUserFlightPreferences;

  const [originOpts, setOriginOpts] = React.useState<any[]>([]);
  const [originLoading, setOriginLoading] = React.useState(false);
  const [originModalOpen, setOriginModalOpen] = React.useState<boolean>(false);
  const [origin, setOrigin] = React.useState<ITripTerminus | null>(null);
  const [localFareClassRadioSelect, setLocalFareClassRadioSelect] =
    React.useState<FareclassShelfBrandName | null>(null);
  const [fareclassInfoModalOpen, setFareclassInfoModalOpen] =
    React.useState<boolean>(false);
  const [lccModalOpen, setLCCModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (localHomeAirport) {
      setOrigin(localHomeAirport);
    }
  }, [localHomeAirport]);

  React.useMemo(() => {
    Object.keys(localFareClasses).some((key) => {
      if (localFareClasses[key]) {
        setLocalFareClassRadioSelect(key as FareclassShelfBrandName);
      }
    });
  }, [localFareClasses]);

  const localStateDiffersFromSaved = React.useMemo(
    () => !isEqual(userFlightPreferences, localUserFlightPreferences),
    [userFlightPreferences, localUserFlightPreferences]
  );

  const prevLocalStateDiffersFromSaved = usePrevious(
    localStateDiffersFromSaved
  );
  const isSaving =
    isLoadingUserFlightPreferences || isUpdatingUserFlightPreferences;

  const handleSave = (clear?: boolean) => {
    if (!clear && localUserFlightPreferences) {
      updateUserFlightPreferences(localUserFlightPreferences);
    } else {
      updateUserFlightPreferences({
        fareClasses: {
          basic: false,
          standard: false,
          enhanced: false,
          premium: false,
          luxury: false,
        },
        airlines: [],
        nonStopOnly: false,
      });
    }

    trackEvent({
      eventName: SAVED_FLIGHT_PREFERENCES,
      properties: addTrackingProperties(expState.trackingProperties),
    });
  };

  const handleClear = () => {
    setLocalUseFlightPreferences({
      fareClasses: {
        basic: false,
        standard: false,
        enhanced: false,
        premium: false,
        luxury: false,
      },
      airlines: [],
      nonStopOnly: false,
    });
    setLocalFareClassRadioSelect(null);
    setOrigin(null);

    handleSave(true);
  };

  React.useEffect(() => {
    setLocalUseFlightPreferences({
      ...localUserFlightPreferences,
      ...(origin ? { homeAirport: origin } : {}),
    });
  }, [origin]);

  React.useEffect(() => {
    fetchUserFlightPreferences();
    resetUpdateUserFlightPreferencesCallState();
  }, []);

  React.useEffect(() => {
    if (userFlightPreferences) {
      setLocalUseFlightPreferences(userFlightPreferences);
    }
  }, [userFlightPreferences]);

  React.useEffect(() => {
    if (
      updateUserFlightPreferencesCallstate === CallState.Success &&
      matchesMobile
    ) {
      window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
    }
  }, [updateUserFlightPreferencesCallstate]);

  const airlineOptionIsChecked = (airlineOption: AirlineOpenEnum): boolean => {
    return localAirlines.some(
      (airline: AirlineOpenEnum) => airline == airlineOption
    );
  };

  const handleAirlineChange = (airlineOption: AirlineOpenEnum) => {
    if (airlineOptionIsChecked(airlineOption)) {
      const selectedAfterRemove: AirlineOpenEnum[] = localAirlines.filter(
        (selectedAirline: AirlineOpenEnum) => selectedAirline !== airlineOption
      );
      setLocalUseFlightPreferences({
        ...localUserFlightPreferences,
        airlines: selectedAfterRemove,
      });
    } else {
      const newSelected = [
        ...localAirlines,
        airlineOption,
      ] as AirlineOpenEnum[];
      setLocalUseFlightPreferences({
        ...localUserFlightPreferences,
        airlines: newSelected,
      });
    }
  };

  const handleStopOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalUseFlightPreferences({
      ...localUserFlightPreferences,
      nonStopOnly: event.target.value === "Nonstop only",
    });
  };

  const handleFareClassChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newFareClasses: FareclassOptionFilter = {
      basic: false,
      standard: false,
      enhanced: false,
      premium: false,
      luxury: false,
    };
    newFareClasses[event.target.value] = true;
    setLocalUseFlightPreferences({
      ...localUserFlightPreferences,
      fareClasses: newFareClasses,
    });
  };

  const handleDeselectFareClass = (event: any) => {
    const newFareClasses: FareclassOptionFilter = {
      basic: false,
      standard: false,
      enhanced: false,
      premium: false,
      luxury: false,
    };
    if (localFareClasses[event.target.value]) {
      setLocalFareClassRadioSelect(null);
      setLocalUseFlightPreferences({
        ...localUserFlightPreferences,
        fareClasses: newFareClasses,
      });
    }
  };

  const fetchLocationCategories = debounce((searchText: string) => {
    const requestBody: ILocationQueryLabel = {
      LocationQuery: LocationQueryEnum.Label,
      l: searchText,
    };

    fetchLocations(requestBody)
      .then((res) => {
        const { categories } = res;

        setOriginOpts(categories);
      })
      .catch(() => {
        setOriginOpts([]);
      })
      .finally(() => {
        setOriginLoading(false);
      });
  }, 300);

  const topSections: {
    class: string;
    title: string;
    description: string;
    icon?: IconName;
    tooltip?: string;
    content?: JSX.Element;
  }[] = [
    {
      class: "flight-preference-home-airport",
      title: "What is your home airport?",
      description:
        "We’ll use this airport to populate the departing airport field when you search for a flight.",
      content: matchesMobile ? (
        <MobileSearchFieldButton
          value={origin?.label}
          label="Enter home airport"
          icon={<Icon name={IconName.HomeIcon} />}
          buttonClass={clsx("flight-preferences-origin-autocomplete", {
            "has-value": !!origin,
          })}
          renderPopup={() => (
            <MobileLocationSearchModal
              open={originModalOpen}
              setOpen={setOriginModalOpen}
              fetchLocationCategories={fetchLocationCategories}
              originLoading={originLoading}
              setOrigin={setOrigin}
              origin={origin}
              originOpts={originOpts}
            />
          )}
          onClick={() => setOriginModalOpen(true)}
        />
      ) : (
        <B2BSearchAutocomplete
          className="flight-preferences-origin-autocomplete"
          customIcon={<Icon name={IconName.HomeIcon} />}
          fetchValueCategories={(searchText) =>
            fetchLocationCategories(searchText ?? "")
          }
          getOptionSelected={(option, value) =>
            value?.id?.code?.code === option?.id?.code?.code
          }
          label={"Enter home airport"}
          loading={originLoading}
          setValue={(value) => {
            if (value) {
              setOrigin(value);
            }
          }}
          value={origin}
          valueCategories={originOpts}
        />
      ),
    },
  ];

  const bottomSections: {
    class: string;
    title: string;
    description: string;
    icon?: IconName;
    tooltip?: string;
    content?: JSX.Element;
    onTooltipClick?: () => void;
    ariaLabel?: string;
  }[] = [
    {
      class: "flight-preference-fare-class",
      title: "Fare class",
      description: "Select your preferred fare classes.",
      icon: IconName.FareIcon,
      onTooltipClick: () => setFareclassInfoModalOpen(true),
      content: (
        <Box className="flight-preferences-content-section-options">
          <RadioGroup
            value={localFareClassRadioSelect}
            onChange={handleFareClassChange}
          >
            {allFareClassOptions.map((fareClassOption) => (
              <FormControlLabel
                className="fare-class-selection-form-control-label"
                key={fareClassOption}
                value={fareClassOption}
                label={
                  <span className="fare-class-label">
                    {getFareClassLabel(fareClassOption)}
                  </span>
                }
                labelPlacement={matchesMobile ? "start" : "end"}
                control={
                  <Radio
                    className="fare-class-selection-radio"
                    onClick={handleDeselectFareClass}
                  />
                }
              />
            ))}
          </RadioGroup>
        </Box>
      ),
      ariaLabel: "Fare class info modal button",
    },
    {
      class: "flight-preference-airline",
      title: "Airline",
      description: "Select your preferred airlines.",
      icon: IconName.DiagonalGreyAirplane,
      content: (
        <Box className="flight-preferences-content-section-options">
          {allAirlineOptions.map((airlineOption) => {
            const checked = airlineOptionIsChecked(airlineOption.value);
            return (
              <FormControlLabel
                labelPlacement={matchesMobile ? "start" : "end"}
                key={`${airlineOption.value}`}
                onKeyDown={(e) => {
                  if (e && e.key === "Enter") {
                    handleAirlineChange(airlineOption.value);
                  }
                }}
                control={
                  <Checkbox
                    inputProps={{ "aria-labelledby": `${airlineOption.value}` }}
                    checked={checked}
                    onChange={() => handleAirlineChange(airlineOption.value)}
                    value={airlineOption.value}
                    className="airline-selection-checkbox"
                  />
                }
                label={
                  <Box
                    id={`${airlineOption.value}`}
                    className="airline-label-wrapper"
                  >
                    {!airlineOption.tooltip && (
                      <Typography variant="body1" className="airline-label">
                        {airlineOption.label}
                      </Typography>
                    )}

                    {airlineOption.tooltip &&
                      (matchesMobile ? (
                        <>
                          <ButtonWrap
                            onClick={() => setLCCModalOpen(true)}
                            className="tooltip-content"
                          >
                            <Typography
                              variant="body1"
                              className="tooltip-text"
                            >
                              {airlineOption.label}
                            </Typography>
                            <Icon name={IconName.InfoCircle} />
                          </ButtonWrap>
                          <MobilePopoverCard
                            className="lcc-popover"
                            open={lccModalOpen}
                            onClose={() => setLCCModalOpen(false)}
                            headerElement={
                              <Box className="modal-header-container">
                                <Typography className="header-title">
                                  Low cost carriers
                                </Typography>
                                <ActionLink
                                  className="modal-close-button"
                                  onClick={() => {
                                    setLCCModalOpen(false);
                                  }}
                                  content={
                                    <CloseButtonIcon className="close-button-icon" />
                                  }
                                  label="Close"
                                  showTappableArea
                                />
                              </Box>
                            }
                          >
                            <Typography
                              variant="subtitle2"
                              className="tooltip-text"
                            >
                              {airlineOption.tooltip}
                            </Typography>
                          </MobilePopoverCard>
                        </>
                      ) : (
                        <Tooltip
                          title={
                            <Typography
                              variant="subtitle2"
                              className="tooltip-text"
                              id="lcc-info-tooltip"
                            >
                              {airlineOption.tooltip}
                            </Typography>
                          }
                          placement="bottom"
                          tabIndex={0}
                        >
                          <div
                            className="tooltip-content"
                            role="button"
                            aria-labelledby="lcc-info-tooltip"
                            aria-describedby="lcc-info-tooltip"
                            tabIndex={0}
                          >
                            <Typography
                              variant="body1"
                              className="tooltip-text"
                            >
                              {airlineOption.label}
                            </Typography>
                            <Icon name={IconName.InfoCircle} />
                          </div>
                        </Tooltip>
                      ))}
                  </Box>
                }
                className="airline-selection-form-control-label"
              />
            );
          })}
        </Box>
      ),
    },
    {
      class: "flight-preference-non-stop",
      title: "Number of stops",
      description: "Select your preferred number of stops.",
      icon: IconName.StopsFilterIcon,
      content: (
        <Box className="flight-preferences-content-section-options">
          <RadioGroup
            value={localNonStopOnly ? "Nonstop only" : "Any number of stops"}
            onChange={handleStopOptionChange}
          >
            {allStopOptions.map((stopOption) => (
              <FormControlLabel
                className="stop-option"
                key={stopOption.value}
                value={stopOption.value}
                label={
                  <span className="stop-option-label">{stopOption.label}</span>
                }
                labelPlacement={matchesMobile ? "start" : "end"}
                control={<Radio className="stop-option-radio" />}
              />
            ))}
          </RadioGroup>
        </Box>
      ),
    },
  ];
  return (
    <Box className={clsx("flight-preferences-root", { mobile: matchesMobile })}>
      {isLoadingUserFlightPreferences ? (
        <Box className="flight-preferences-loading-container">
          <B2BSpinner />
        </Box>
      ) : (
        <Box className="flight-preferences-content">
          {userHasSetFlightPreferences && matchesMobile && (
            <ButtonWrap
              className="flight-preferences-cta reset-flight-prefs"
              onClick={handleClear}
              disabled={isSaving}
            >
              {RESET_CTA_TEXT}
            </ButtonWrap>
          )}
          <hr />
          {matchesMobile &&
            updateUserFlightPreferencesCallstate === CallState.Success &&
            !localStateDiffersFromSaved &&
            !prevLocalStateDiffersFromSaved && (
              <UserPreferencesSavedBanner type="flight" />
            )}
          <Box className="flight-preferences-content-top">
            {topSections.map((section) => (
              <Box
                className={clsx(
                  "flight-preferences-content-section",
                  section.class
                )}
              >
                <Box
                  className={clsx("flight-preferences-content-section-heading")}
                >
                  <Typography
                    className={clsx("flight-preferences-content-section-title")}
                    variant="h3"
                  >
                    {section.icon && <Icon name={section.icon} />}
                    {section.title}
                  </Typography>
                  <Typography
                    className={clsx(
                      "flight-preferences-content-section-description"
                    )}
                  >
                    {section.description}
                  </Typography>
                </Box>
                {section.content}
              </Box>
            ))}
          </Box>
          <hr />
          <Box className="flight-preferences-content-bottom">
            {bottomSections.map((section, index) => (
              <Box
                className={clsx(
                  "flight-preferences-content-section",
                  section.class
                )}
              >
                <Box
                  className={clsx("flight-preferences-content-section-heading")}
                >
                  {section.onTooltipClick ? (
                    <ButtonWrap
                      className="flight-preferences-content-section-title-interactive"
                      onClick={section.onTooltipClick}
                      aria-label={section.ariaLabel}
                    >
                      <Typography
                        variant="h3"
                        className={clsx(
                          "flight-preferences-content-section-title"
                        )}
                      >
                        {section.icon && <Icon name={section.icon} />}
                        {section.title}
                      </Typography>
                      <Icon name={IconName.InfoCircle} />
                    </ButtonWrap>
                  ) : (
                    <Typography
                      variant="h3"
                      className={clsx(
                        "flight-preferences-content-section-title"
                      )}
                    >
                      {section.icon && <Icon name={section.icon} />}
                      {section.title}
                    </Typography>
                  )}

                  <Typography
                    variant="body2"
                    className={clsx(
                      "flight-preferences-content-section-description"
                    )}
                  >
                    {section.description}
                  </Typography>
                </Box>
                {section.content}
                {matchesMobile && index < bottomSections.length - 1 && <hr />}
              </Box>
            ))}
          </Box>
          {!localStateDiffersFromSaved &&
            userHasSetFlightPreferences &&
            !matchesMobile && (
              <Box className="flight-preferences-ctas">
                <ButtonWrap
                  className="flight-preferences-cta reset-flight-prefs"
                  onClick={handleClear}
                  disabled={isSaving}
                >
                  {RESET_CTA_TEXT}
                </ButtonWrap>
              </Box>
            )}
          {localStateDiffersFromSaved && (
            <Box className="flight-preferences-ctas">
              <ButtonWrap
                className="flight-preferences-cta reset-flight-prefs"
                onClick={handleClear}
                disabled={isSaving}
              >
                {RESET_CTA_TEXT}
              </ButtonWrap>
              <ButtonWrap
                className="flight-preferences-cta save-flight-prefs"
                onClick={() => handleSave()}
                disabled={isSaving}
              >
                {isSaving ? SAVE_CTA_ACTIVE_TEXT : SAVE_CTA_TEXT}
              </ButtonWrap>
            </Box>
          )}
          <FareClassInfoModal
            open={fareclassInfoModalOpen}
            setOpen={setFareclassInfoModalOpen}
            isMobile={matchesMobile}
          />
        </Box>
      )}

      {!matchesMobile &&
        updateUserFlightPreferencesCallstate === CallState.Success &&
        !localStateDiffersFromSaved &&
        !prevLocalStateDiffersFromSaved && (
          <UserPreferencesSavedBanner type="flight" />
        )}
    </Box>
  );
};
