import { put } from "redux-saga/effects";
import { actions } from "../actions";
import { UserHotelPreferencesPayload } from "redmond";
import fetchUserHotelPreferences from "../../../api/v1/user/fetchUserHotelPreferences";

export function* fetchUserHotelPreferencesSaga(
  _: actions.IFetchUserHotelPreferences
) {
  try {
    const hotelPreferences: UserHotelPreferencesPayload =
      yield fetchUserHotelPreferences();

    yield put(
      actions.setUserHotelPreferences({
        hotelPreferences,
      })
    );
  } catch (e) {
    yield put(actions.setUserHotelPreferencesCallStateFailed());
  }
}
