import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { ContactInfo } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  getUserContactInfo,
  getUserContactInfoCallState,
} from "../../../../modules/preferences/reducer";
import { CallState } from "redmond";
import { actions } from "../../../../modules/preferences/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    contactInfo: getUserContactInfo(state),
    isLoadingContactInfo:
      getUserContactInfoCallState(state) === CallState.InProcess,
  };
};

const mapDispatchToProps = {
  fetchUserContactInfo: actions.fetchUserContactInfo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ContactInfoConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedContactInfo = connector(withRouter(ContactInfo));
