import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { CustomerProfileLanding } from "./component";

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CustomerProfileLandingConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCustomerProfileLanding = connector(
  withRouter(CustomerProfileLanding)
);
