import {
  IPerson,
  UserFlightPreferencesPayload,
  Payment,
  PaymentVerifyResultEnum,
  UserHotelPreferencesPayload,
} from "redmond";
import { actions, actionTypes } from "../actions";
import { UserContactInfoResponse } from "../../../api/v1/user/fetchUserContactInfo";

export interface ICustomerProfilePreferencesState {
  userPassengers: IPerson[];
  userPassengerCallState: CallState;
  userHotelPreferences?: UserHotelPreferencesPayload;
  userHotelPreferencesCallState: CallState;
  updateUserHotelPreferencesCallState: CallState;
  userContactInfo?: UserContactInfoResponse;
  userContactInfoCallState: CallState;
  userFlightPreferences: UserFlightPreferencesPayload;
  userFlightPreferencesCallState: CallState;
  updateUserFlightPreferencesCallState: CallState;
  paymentMethods: Payment[];
  listPaymentMethodCallState: CallState;
  verifyPaymentMethodCallState: CallState;
  deletePaymentMethodCallState: CallState;
  verifyPaymentMethodResult?: PaymentVerifyResultEnum;
}

export enum CallState {
  NotCalled,
  InProcess,
  Success,
  Failed,
}

const initialState: ICustomerProfilePreferencesState = {
  userPassengers: [],
  userPassengerCallState: CallState.NotCalled,
  userHotelPreferences: undefined,
  userHotelPreferencesCallState: CallState.NotCalled,
  updateUserHotelPreferencesCallState: CallState.NotCalled,
  userContactInfo: undefined,
  userContactInfoCallState: CallState.NotCalled,
  userFlightPreferences: {
    fareClasses: {
      basic: false,
      standard: false,
      enhanced: false,
      premium: false,
      luxury: false,
    },
    airlines: [],
    nonStopOnly: false,
  },
  userFlightPreferencesCallState: CallState.NotCalled,
  updateUserFlightPreferencesCallState: CallState.NotCalled,
  paymentMethods: [],
  listPaymentMethodCallState: CallState.NotCalled,
  verifyPaymentMethodCallState: CallState.NotCalled,
  deletePaymentMethodCallState: CallState.NotCalled,
  verifyPaymentMethodResult: undefined,
};

export function reducer(
  state: ICustomerProfilePreferencesState = initialState,
  action: actions.CustomerProfilePreferencesActions
): ICustomerProfilePreferencesState {
  switch (action.type) {
    case actionTypes.FETCH_USER_PASSENGERS:
      return {
        ...state,
        userPassengerCallState: CallState.InProcess,
      };

    case actionTypes.SET_USER_PASSENGERS:
      return {
        ...state,
        userPassengerCallState: action.userPassengerCallState,
        userPassengers: action.userPassengers,
      };
    case actionTypes.SET_USER_PASSENGERS_CALL_STATE_FAILED:
      return {
        ...state,
        userPassengerCallState: CallState.Failed,
        userPassengers: [],
      };

    case actionTypes.FETCH_USER_HOTEL_PREFERENCES:
      return { ...state, userHotelPreferencesCallState: CallState.InProcess };

    case actionTypes.SET_USER_HOTEL_PREFERENCES:
      return {
        ...state,
        userHotelPreferences: action.hotelPreferences,
        userHotelPreferencesCallState: CallState.Success,
      };

    case actionTypes.SET_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED:
      return { ...state, userHotelPreferencesCallState: CallState.Failed };

    case actionTypes.UPDATE_USER_HOTEL_PREFERENCES:
      return {
        ...state,
        updateUserHotelPreferencesCallState: CallState.InProcess,
        userHotelPreferences: action.hotelPreferences,
      };

    case actionTypes.SET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE_SUCCESS:
      return {
        ...state,
        updateUserHotelPreferencesCallState: CallState.Success,
      };

    case actionTypes.SET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE_FAILED:
      return {
        ...state,
        updateUserHotelPreferencesCallState: CallState.Failed,
      };

    case actionTypes.FETCH_USER_CONTACT_INFO:
      return {
        ...state,
        userContactInfoCallState: CallState.InProcess,
      };

    case actionTypes.SET_USER_CONTACT_INFO:
      return {
        ...state,
        userContactInfo: action.userContactInfo,
        userContactInfoCallState: CallState.Success,
      };

    case actionTypes.SET_USER_CONTACT_INFO_CALL_STATE_FAILED:
      return { ...state, userContactInfoCallState: CallState.Failed };

    case actionTypes.FETCH_USER_FLIGHT_PREFERENCES:
      return {
        ...state,
        userFlightPreferencesCallState: CallState.InProcess,
      };
    case actionTypes.SET_USER_FLIGHT_PREFERENCES:
      return {
        ...state,
        userFlightPreferences: action.flightPreferences,
        userFlightPreferencesCallState: CallState.Success,
      };
    case actionTypes.UPDATE_USER_FLIGHT_PREFERENCES:
      return {
        ...state,
        updateUserFlightPreferencesCallState: CallState.InProcess,
      };
    case actionTypes.SET_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED:
      return {
        ...state,
        userFlightPreferencesCallState: CallState.Failed,
      };
    case actionTypes.SET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE_FAILED:
      return {
        ...state,
        updateUserFlightPreferencesCallState: CallState.Failed,
      };
    case actionTypes.SET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE_SUCCESS:
      return {
        ...state,
        updateUserFlightPreferencesCallState: CallState.Success,
      };
    case actionTypes.LIST_PAYMENT_METHODS:
      return {
        ...state,
        listPaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.paymentMethods,
        listPaymentMethodCallState: CallState.Success,
      };
    case actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED:
      return {
        ...state,
        listPaymentMethodCallState: CallState.Failed,
      };

    case actionTypes.DELETE_PAYMENT_METHOD:
      return {
        ...state,
        deletePaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS:
      return {
        ...state,
        deletePaymentMethodCallState: CallState.Success,
      };

    case actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_FAILED:
      return {
        ...state,
        deletePaymentMethodCallState: CallState.Failed,
      };

    case actionTypes.VERIFY_PAYMENT_METHOD:
      return {
        ...state,
        verifyPaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS:
      return {
        ...state,
        verifyPaymentMethodCallState: CallState.Success,
      };

    case actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED:
      return {
        ...state,
        verifyPaymentMethodResult: action.result,
        verifyPaymentMethodCallState: CallState.Failed,
      };

    case actionTypes.RESET_UPDATE_USER_FLIGHT_PREFERENCES_CALL_STATE:
      return {
        ...state,
        updateUserFlightPreferencesCallState: CallState.NotCalled,
      };

    case actionTypes.RESET_UPDATE_USER_HOTEL_PREFERENCES_CALL_STATE:
      return {
        ...state,
        updateUserHotelPreferencesCallState: CallState.NotCalled,
      };
    default:
      return state;
  }
}

export * from "./selectors";
