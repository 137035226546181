export const SEARCH = "Search";
export const MY_PROFILE = "My profile";
export const getTitle = (firstName: string) => {
  return `Welcome, ${firstName}`;
};
export const SUBTITLE =
  "Manage preferences, frequent flyer programs, payment methods and more.";

export const FOOTER_PRIMARY_TEXT = "Your next adventure starts here.";
export const FOOTER_SECONDARY_TEXT =
  "Book with flexibility and enjoy elevated travel benefits.";
export const FOOTER_CTA_TEXT = "Book travel";
