import axios from "axios";
import { config } from "../../config";
import { PersonId } from "redmond";
import { deleteUserPassengerPath } from "../paths";

export const deleteUserPassenger = (
  travelerId: PersonId
): Promise<IDeleteUserPassengerResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        deleteUserPassengerPath,
        {
          personId: travelerId,
        },
        {
          baseURL: config.baseURL,
        }
      );

      const responseBody: IDeleteUserPassengerResponse = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });

export interface IDeleteUserPassengerRequest {
  personId: PersonId;
}

export interface IDeleteUserPassengerResponse {}
