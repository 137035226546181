import { connect, ConnectedProps } from "react-redux";
import { CallState } from "redmond";
import { HotelPreferences } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  getHasUserSetHotelPreferences,
  getUpdateUserHotelPreferencesCallState,
  getUserHotelPreferences,
  getUserHotelPreferencesCallState,
} from "../../../../modules/preferences/reducer";
import {
  fetchUserHotelPreferences,
  resetUpdateUserHotelPreferencesCallState,
  updateUserHotelPreferences,
} from "../../../../modules/preferences/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  const updateUserHotelPreferencesCallstate =
    getUpdateUserHotelPreferencesCallState(state);
  return {
    userHotelPreferences: getUserHotelPreferences(state),
    isLoadingUserHotelPreferences:
      getUserHotelPreferencesCallState(state) === CallState.InProcess,
    isUpdatingUserHotelPreferences:
      updateUserHotelPreferencesCallstate === CallState.InProcess,
    updateUserHotelPreferencesCallstate,
    hasUserSetHotelPreferences: getHasUserSetHotelPreferences(state),
  };
};

const mapDispatchToProps = {
  fetchUserHotelPreferences,
  updateUserHotelPreferences,
  resetUpdateUserHotelPreferencesCallState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelPreferencesConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedHotelPreferences = connector(HotelPreferences);
