import axios from "axios";
import { ILocationQueryLabel, IResponse } from "redmond";

import { config } from "../../config";
import { fetchLocationsPath } from "../paths";

const fetchLocations = (body: ILocationQueryLabel): Promise<IResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(fetchLocationsPath, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default fetchLocations;
