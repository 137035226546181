import { connect, ConnectedProps } from "react-redux";

import { PaymentMethodsSection } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  getDeletePaymentMethodCallState,
  getListPaymentMethodsCallState,
  getPaymentMethods,
  getVerifyPaymentMethodCallState,
  getVerifyPaymentMethodResults,
} from "../../../../modules/preferences/reducer";
import {
  deletePaymentMethod,
  listPaymentMethods,
  verifyPaymentMethod,
} from "../../../../modules/preferences/actions/actions";
import { withRouter } from "react-router-dom";
import { getRewardsAccounts } from "../../../../modules/rewards/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    paymentMethods: getPaymentMethods(state),
    rewardsAccounts: getRewardsAccounts(state),
    listPaymentMethodCallState: getListPaymentMethodsCallState(state),
    verifyPaymentMethodCallState: getVerifyPaymentMethodCallState(state),
    deletePaymentMethodCallState: getDeletePaymentMethodCallState(state),
    verifyPaymentMethodResults: getVerifyPaymentMethodResults(state),
  };
};

const mapDispatchToProps = {
  listPaymentMethods,
  deletePaymentMethod,
  verifyPaymentMethod,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PaymentMethodsSectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPaymentMethodsSection = connector(
  withRouter(PaymentMethodsSection)
);
