import { Box, Typography } from "@material-ui/core";
import React from "react";
import "./styles.scss";
import {
  EMAIL_HEADING_TEXT,
  INFO_TEXT,
  PHONE_NUMBER_HEADING_TEXT,
} from "./textConstants";
import { ContactInfoConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import { B2BSpinner, useDeviceTypes } from "halifax";
import clsx from "clsx";

export interface ICustomerContactInfoProps
  extends ContactInfoConnectorProps,
    RouteComponentProps {}

export const ContactInfo = ({
  contactInfo,
  fetchUserContactInfo,
  isLoadingContactInfo,
}: ICustomerContactInfoProps) => {
  const { matchesMobile } = useDeviceTypes();

  React.useEffect(() => {
    fetchUserContactInfo();
  }, []);

  return (
    <Box
      className={clsx("contact-info-section-root", { mobile: matchesMobile })}
    >
      {isLoadingContactInfo ? (
        <Box className="contact-info-loading-container">
          <B2BSpinner />
        </Box>
      ) : (
        <>
          <Box className="contact-info-content-row">
            <Box className="contact-info-content-col">
              <Typography className="contact-info-heading">
                {PHONE_NUMBER_HEADING_TEXT}
              </Typography>
              <Typography className="contact-info-value">
                {contactInfo?.phoneNumber || "-"}
              </Typography>
            </Box>
            {matchesMobile && <hr />}
            <Box className="contact-info-content-col">
              <Typography className="contact-info-heading">
                {EMAIL_HEADING_TEXT}
              </Typography>
              <Typography className="contact-info-value">
                {contactInfo?.emailAddress || "-"}
              </Typography>
            </Box>
          </Box>
          <hr />

          <Typography className="contact-info-extra-text">
            {INFO_TEXT}
          </Typography>
        </>
      )}
    </Box>
  );
};
