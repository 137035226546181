import { put } from "redux-saga/effects";
import { actions } from "../actions";
import { updateUserPassenger } from "../../../api/v1/user/updateUserPassenger";
import {
  fetchUserPassengers,
  IListUserPassengersResponse,
} from "../../../api/v1/user/fetchUserPassengers";
import {
  CallState,
  Person,
  SAVED_NEW_TRAVELER,
  SavedNewTravelerProperties,
} from "redmond";
import { trackEvent } from "../../../api/v1/analytics/trackEvent";
import { addTrackingProperties } from "../../../context/experiments";

export function* updateUserPassengerSaga(action: actions.IUpdateUserPassenger) {
  try {
    // Check if passport field is valid or filter it
    // & filter driverLicense field
    // ^ Adding back driverLicense field as part of BOPS-628
    const person = action.traveler;
    const { passport, ...restOfPayload } = person;
    const personToUpdate: Person = { ...restOfPayload };
    if (passport?.countryOfIssue?.length === 2) {
      personToUpdate.passport = passport;
    }

    yield updateUserPassenger({ person: personToUpdate });

    const userPassengers: IListUserPassengersResponse =
      yield fetchUserPassengers({});

    yield put(
      actions.setUserPassengers({
        userPassengerCallState: CallState.Success,
        userPassengers: userPassengers,
      })
    );

    if (action.isNewTraveler) {
      trackEvent({
        eventName: SAVED_NEW_TRAVELER,
        properties: addTrackingProperties(undefined, {
          entry_type: "customer_profile",
        } as SavedNewTravelerProperties),
      });
    }
  } catch (e) {
    yield put(actions.setUserPassengersCallStateFailed());
  }
}
